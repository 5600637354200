import "./loginexb.scss";
import React, { useState, Component, useRef } from "react";
import { authenticate } from "../../services/TransactionsService";
import ReactTyped from 'react-typed';
import DrawSVG from 'react-svg-drawing';
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
const LoginScreen = () => {
    //User State
    const [username, setUsername] = useState("");
    //Password State
    const [password, setPassword] = useState("");
    //Error State
    const [error, setError] = useState("");
    //Ref to see if the user is empty
    const userref = useRef(null);
    //Ref to see if the password is empty
    const passwordref = useRef(null);
    //Password Visibility Type
    const [type, setType] = useState('password');
    //Password Visibility Icon
    const [isActive, setIsActive] = useState(false);
    //Backdrop State
    const [open, setOpen] = useState(false);
    //Password Visibility
    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }
    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            if (username.trim().length == 0) {
                if (userref.current) {
                    userref.current.focus();
                    return;
                }
            }
            if (password.trim().length == 0) {
                if (passwordref.current) {
                    passwordref.current.focus();
                    return;
                }
            }
            //Activate Backdrop
            setOpen(true);
            // Call the authenticate function with the user credentials
            const token = await authenticate(username.trim(), password.trim());

            // Check if response is successful
            if (token) {
                // console.log("Login successful!");
                localStorage.setItem("username", username);
                localStorage.setItem("password", password);
                // localStorage.setItem("loggedIn", "true");
                localStorage.setItem("token", token);
                window.location.reload(); // Refresh the page
            } else {
                // If login failed, show an error
                localStorage.clear();
                setError("Invalid username or password");
                // console.log("Login failed!");
            }
        } catch (err) {
            // If there's an error with the request, show an error
            setError(err);
            console.log(err);
            // If login failed, show an error
            localStorage.clear();
            window.location.reload(); // Refresh the page
        }
    };

    // Input Border Anmiation
    const [isFocuseduser, setIsFocuseduser] = useState(false);
    const [isFocusedpassword, setIsFocusedpassword] = useState(false);
    const handleFocususer = () => {
        setTimeout(() => {
            setIsFocuseduser(true);
        }, 301);

    };
    const handleBluruser = event => {
        event.preventDefault();

        if (username.trim().length !== 0) {
            // console.log('input value is NOT empty');
        } else {
            setIsFocuseduser(false);
        }
    };
    const handleFocuspassword = () => {
        setTimeout(() => {
            setIsFocusedpassword(true);
        }, 301);

    };
    const handleBlurpassword = event => {
        event.preventDefault();

        if (password.trim().length !== 0) {
            // console.log('input value is NOT empty');
        } else {
            setIsFocusedpassword(false);
        }
    };

    return (
        <div className="h-full flex content-center">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="w-3/5 hidden sm:flex  self-center bgimage h-full ">
                <div className="flex flex-center flex-col self-center mx-auto w-full h-full pt-16 pl-16 pb-16 relative">
                    <div className="h-full flex flex-col justify-center -mt-4">
                        <ReactTyped
                            className="text-6xl font-bold text-white"
                            loop
                            typeSpeed={100}
                            backSpeed={60}
                            strings={["Inovadora", "Eficiente", "Segura", "Integrada"]}
                            smartBackspace
                            shuffle={false}
                            backDelay={1500}
                            fadeOut={false}
                            fadeOutDelay={100}
                            loopCount={0}
                            showCursor
                            cursorChar="|"
                        />
                        <span className="flex w-8/12 mt-6 font-semibold text-2xl leading-normal text-white">
                            Com segurança de ponta e uma abordagem centrada no usuário, capacitamos você a conduzir seus negócios de forma ágil e confiável. Descubra a evolução dos pagamentos conosco, onde a tecnologia encontra a praticidade.
                        </span>

                    </div>
                    <div className="absolute bottom-5">
                        {/* <a href="https://www.izipaynow.com" target="_blank" className="flex w-8/12 font-semibold leading-normal text-white text-nowrap">
                            www.izipaynow.com
                        </a> */}
                        <a href="https://www.instagram.com/izipay_now/" target="_blank" className="flex w-8/12 font-semibold leading-normal text-white text-nowrap">
                            @Izipay_now
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=5551981899939&text=Ol%C3%A1,%20vim%20atrav%C3%A9s%20do%20site%20e%20quero%20saber%20mais%20sobre%20a%20Izipay%20Now" className="flex w-8/12 font-semibold leading-normal text-white text-nowrap" target="_blank">
                            +55 51 98189-9939
                        </a>
                    </div>

                    {/* <div>
                        <ReactTyped
                            strings={["Uma fintech inovadora", "Uma fintech eficiente", "Uma fintech segura", "Uma fintech integrada"]}
                            typeSpeed={50}
                            backSpeed={50}
                            backDelay={1}
                            loop
                            smartBackspace
                        />
                    </div> */}
                </div>
            </div>
            <div className="w-full sm:w-2/5 flex flex-col self-center h-full my-8">

                {error && <div className="error">{error}</div>}
                <div className="w-10/12 sm:w-7/12 mx-auto mt-24 sm:mt-40">
                    <div className="flex justify-center mb-20">
                        <div className="flex relative items-center mirrored">

                            <div className="redz">
                                <DrawSVG width="100px" strokeWidth="75" stroke="#e94633" duration="3000" easing="easeInQuad">
                                    <svg viewBox="363 1015 2246 1840">
                                        <linearGradient id="animationred">
                                            <stop offset="0" stopColor="#e94633">
                                                <animate
                                                    dur="1.5s"
                                                    attributeName="offset"
                                                    fill="freeze"
                                                    begin="2s"
                                                    from="0"
                                                    to="1"
                                                />
                                            </stop>
                                            <stop offset="0" stopColor="#fff">
                                                <animate
                                                    dur="1.5s"
                                                    attributeName="offset"
                                                    fill="freeze"
                                                    begin="2s"
                                                    from="0"
                                                    to="1"
                                                />
                                            </stop>
                                        </linearGradient>
                                        <path fill="url(#animationred)" d="M867 2817 c42 -68 227 -316 239 -321 9 -3 153 -6 320 -6 l305 0 -14 -20 c-8 -12 -66 -76 -129 -143 -63 -67 -213 -228 -333 -357 -120 -129 -252 -271 -294 -315 -67 -70 -223 -236 -442 -470 -36 -38 -81 -87 -99 -107 l-32 -38 234 0 234 0 144 148 c79 81 194 199 255 262 196 203 725 751 840 870 182 188 329 342 340 355 6 6 41 44 80 83 l69 72 -862 0 c-705 0 -862 -2 -855 -13z" />
                                    </svg>
                                </DrawSVG>
                            </div>
                            <div className="bluez">
                                <DrawSVG width="100px" strokeWidth="75" stroke="#183350" duration="3000" easing="easeInQuad">
                                    <svg viewBox="985 1008 2225 1848">
                                        <linearGradient id="animationblue">
                                            <stop offset="0" stopColor="#183350">
                                                <animate
                                                    dur="1.5s"
                                                    attributeName="offset"
                                                    fill="freeze"
                                                    begin="2s"
                                                    from="0"
                                                    to="1"
                                                />
                                            </stop>
                                            <stop offset="0" stopColor="#fff">
                                                <animate
                                                    dur="1.5s"
                                                    attributeName="offset"
                                                    fill="freeze"
                                                    begin="2s"
                                                    from="0"
                                                    to="1"
                                                />
                                            </stop>
                                        </linearGradient>
                                        <path fill="url(#animationblue)" d="M2604 2687 c-76 -79 -231 -240 -344 -357 -113 -118 -230 -239 -260 -270 -30 -31 -122 -126 -205 -211 -82 -85 -233 -240 -335 -344 -253 -260 -412 -426 -433 -451 l-17 -21 830 1 c456 1 830 4 830 8 0 8 -79 148 -95 168 -7 8 -25 40 -41 70 -16 30 -31 57 -35 60 -3 3 -8 13 -12 22 -6 16 -31 17 -289 17 -156 0 -300 0 -320 1 -22 0 -38 5 -38 11 0 18 145 176 366 400 74 75 134 138 134 140 0 3 74 81 165 174 91 94 228 237 303 318 75 82 192 203 259 269 67 67 120 124 118 128 -3 4 -103 8 -223 9 l-218 2 -140 -144z" />
                                    </svg>
                                </DrawSVG>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h1 className="text-left text-3xl font-semibold"> Fazer login</h1>
                    </div>
                    <div className="flex gap-8 border-b border-gray-light">
                        <a className="font-semibold py-2 border-b-2 border-transparent hover:border-gray-main" href="">Produção</a>
                        <a className="font-semibold py-2 border-b-2 border-transparent hover:border-gray-main" href="">Sandbox</a>
                    </div>
                    <form className="flex flex-col text-center mt-5" onSubmit={handleLogin}>
                        <div className="w-full relative ">
                            <input
                                className={isFocuseduser ? 'animatekeep cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none' : 'cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none'}
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                onFocus={handleFocususer}
                                onBlur={handleBluruser}
                                ref={userref}
                            />
                            <span className="focus-border">
                                <i></i>
                            </span>
                            <div className="top-0.5 left-3 absolute bg-transparent">
                                <span className="text-xs text-gray-300">
                                    User
                                </span>
                            </div>
                        </div>
                        <div className="w-full mt-2 relative">
                            <input
                                className={isFocusedpassword ? 'cursor-text animatekeep animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none' : 'cursor-text animatetest pt-6 pb-2 pr-2 pl-3 w-full border border-gray-main rounded-md outline-none'}
                                type={type}
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onFocus={handleFocuspassword}
                                onBlur={handleBlurpassword}
                                ref={passwordref}
                            />
                            <span className="bottom-4 right-2.5 absolute" onClick={handleToggle}>
                                <div onClick={() => setIsActive(!isActive)}>
                                    {isActive ? <MdVisibility color="#183350" className="cursor-pointer" size={20} /> : <MdVisibilityOff color="#183350" className="cursor-pointer" size={20} />}
                                </div>
                            </span>
                            <div className="top-0.5 left-3 absolute bg-transparent">
                                <span className="text-xs text-gray-300">
                                    Password
                                </span>
                            </div>
                        </div>


                        <div className="text-center mt-6">
                            <button className="btn-primary-large bg-blue-950 border-transparent text-white bg-primary-600 active:bg-primary-700 hover:bg-primary-700 focus:bg-primary-700 active:bg-primary-800 focus:shadow-outline-primary text-sm leading-5 px-4 py-2 font-medium rounded-md inline-flex items-center justify-center border focus:outline-none transition duration-150 ease-in-out" type="submit">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default LoginScreen;
