import React, { useState, useRef, useEffect } from 'react';
import { getUsers } from "../../services/TransactionsService";
import { QueryClient, QueryClientProvider } from "react-query";
import { useTranslation } from "react-i18next";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive';
const queryClient = new QueryClient();
const labelFontSize = "1.05rem";
const theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: labelFontSize,
                    fontWeight: 700
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& > fieldset > legend": {
                        fontSize: `calc(0.75 * ${labelFontSize})`
                    }
                }
            }
        }
    }
});
export default function tableUser ({ filterData }) {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const [loading, setLoading] = useState(true);
    const [usersRows, setusersRows] = useState([]);
    const [totalElements, setTotalElements] = React.useState(1);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(() => {
        const fetchStatement = async () => {
            try {
                setLoading(true);
                /* console.log(page + 1)
                console.log(filterData) */
                const response = await getUsers(page + 1, rowsPerPage, filterData);
                /* console.log("🚀 ~ fetchStatement ~ response:", response) */
                /* response.totals.transactions_qty == 0 ? response.totals.transactions_qty = 60 : ''; */
                setTotalElements(response.totals.users_qty);
                const users = response?.users.map((t) => ({
                    ...t,
                    user_id: t.user_id,
                    is_parent: t?.is_parent,
                    user_parent_id: t?.user_parent_id,
                    description: t?.description,
                    email: t?.email,
                    external_token: t?.external_token,
                    pix_key: t?.pix_key
                }));
                setusersRows(users);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setusersRows([]);
                console.error(error);
                return;
            }
        }; fetchStatement();
        console.log(filterData)
    }, [filterData, page, rowsPerPage]);
    const handleChangePage = (event, newPage) => {
        console.log(newPage)
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 15));
        setPage(0);
    };
    if (loading) {
        return <div className="text-center mt-24"><CircularProgress color="inherit" /></div>; // Render a loading state while data is being fetched
    }
    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell align="right">Nome</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Token</TableCell>
                            <TableCell align="right">Chave Pix</TableCell>
                        </TableRow>
                    </TableHead>
                    {!loading &&
                        <TableBody>
                            {usersRows.map((row) => (
                                <TableRow
                                    key={row.user_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.user_id}
                                    </TableCell>
                                    <TableCell align="right">{row.description}</TableCell>
                                    <TableCell align="right">{row.email}</TableCell>
                                    <TableCell align="right">{row.external_token}</TableCell>
                                    <TableCell align="right">{row.pix_key}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[15]}
                component="div"
                count={totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className='table'
                labelDisplayedRows={({ from, to, count }) => t('Mostrando') + ` ${from} ` + t('a') + ` ${to} ` + t('de um total de') + ` ${count} ` + t('transações')}
            />
        </div>
    );
};