import { DarkModeContext } from "../../context/darkModeContext.jsx";
import { Bar, Line, Doughnut, PolarArea } from 'react-chartjs-2';
import React, { useContext, useEffect, useState, Component, useRef } from 'react';
import { useQuery, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { forceUpdateMetrics, getStatement, getBalance, getMetrics } from '../../services/TransactionsService';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import PixIcon from '@mui/icons-material/Pix';
import SpeedIcon from '@mui/icons-material/Speed';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from "react-i18next"; // Import useTranslation
import { format, subDays } from 'date-fns';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
});
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));
const queryClient = new QueryClient();
const today = Date.now();
const yesterday = subDays(today, 1);
const beforeYesterday = subDays(today, 2);
const threeDaysAgo = subDays(today, 3);
const fourDaysAgo = subDays(today, 4);
const fiveDaysAgo = subDays(today, 5);
const sixDaysAgo = subDays(today, 6);
const sevenDaysAgo = subDays(today, 7);
const Transactions = ({ graphicToDisplay }) => {
  const { darkMode } = useContext(DarkModeContext);
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const today = Date.now();
  const yesterday = subDays(today, 1);
  const beforeYesterday = subDays(today, 2);
  const threeDaysAgo = subDays(today, 3);
  const fourDaysAgo = subDays(today, 4);
  const fiveDaysAgo = subDays(today, 5);
  const sixDaysAgo = subDays(today, 6);
  const sevenDaysAgo = subDays(today, 7);
  let graphic = null;

  if (isMobile) {
    import("../table/tableMobile.scss");
  } else {
    import("../table/table.scss");
  }


  /*
  const { data: response } = useQuery({
    queryKey: ["statementQuery"],
    queryFn: () => getStatement(1, 10),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: false,
  });
  */

  useQuery({
    queryKey: ['force'],
    queryFn: () => forceUpdateMetrics(),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
  const { data: metrics } = useQuery({
    queryKey: ['metrics'],
    queryFn: () => getMetrics(),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 1,
    refetchIntervalInBackground: false,
  });

  const { data: yesterdayMetrics } = useQuery({
    queryKey: ['yesterdayMetrics'],
    queryFn: () => getMetrics(formatDate(yesterday)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const { data: beforeYesterdayMetrics } = useQuery({
    queryKey: ['beforeYesterdayMetrics'],
    queryFn: () => getMetrics(formatDate(beforeYesterday)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const { data: threeDaysAgoMetrics } = useQuery({
    queryKey: ['beforeYesterdayMetrics'],
    queryFn: () => getMetrics(formatDate(threeDaysAgo)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const { data: fourDaysAgoMetrics } = useQuery({
    queryKey: ['fourDaysAgoMetrics'],
    queryFn: () => getMetrics(formatDate(fourDaysAgo)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const { data: fiveDaysAgoMetrics } = useQuery({
    queryKey: ['fiveDaysAgoMetrics'],
    queryFn: () => getMetrics(formatDate(fiveDaysAgo)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const { data: sixDaysAgoMetrics } = useQuery({
    queryKey: ['sixDaysAgoMetrics'],
    queryFn: () => getMetrics(formatDate(sixDaysAgo)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  const { data: sevenDaysAgoMetrics } = useQuery({
    queryKey: ['sevenDaysAgoMetrics'],
    queryFn: () => getMetrics(formatDate(sevenDaysAgo)),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });
  const weekMetricsData = [
    { ...sevenDaysAgoMetrics, date: sevenDaysAgo },
    { ...sixDaysAgoMetrics, date: sixDaysAgo },
    { ...fiveDaysAgoMetrics, date: fiveDaysAgo },
    { ...fourDaysAgoMetrics, date: fourDaysAgo },
    { ...threeDaysAgoMetrics, date: threeDaysAgo },
    { ...beforeYesterdayMetrics, date: beforeYesterday },
    { ...yesterdayMetrics, date: yesterday },
    { ...metrics, date: today }
  ];

  const dailyMetricsPerUser = metrics?.daily_metrics_per_user || []


  const formatCurrency = (value) => {
    const formattedValue = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
    return formattedValue;
  };

  const formatDate = (date) => {
    return format(date, 'yyyy-MM-dd');
  };

  const formatDateToUser = (date) => {
    return format(date, 'dd/MM/yyyy');
  };

  const weekMetrics = {
    labels: weekMetricsData.map(t => formatDateToUser(t.date)),
    datasets: [
      {
        label: 'CASH-IN (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_in),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'CASH-OUT (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_out),
        backgroundColor: 'rgba(218, 165, 32, 0.2)',
        borderColor: 'rgba(219, 165, 32, 0.2)',
        borderWidth: 1,
      }
    ]
  };
  const weekMetricsLine = {
    labels: weekMetricsData.map(t => formatDateToUser(t.date)),
    datasets: [
      {
        label: 'CASH-IN (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_in),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'CASH-OUT (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_out),
        backgroundColor: 'rgba(218, 165, 32, 0.2)',
        borderColor: 'rgba(219, 165, 32, 0.2)',
        borderWidth: 1,
      }
    ]
  };
  const weekMetricsDoughnut = {
    labels: weekMetricsData.map(t => formatDateToUser(t.date)),
    datasets: [
      {
        label: 'CASH-IN (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_in),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'CASH-OUT (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_out),
        backgroundColor: 'rgba(218, 165, 32, 0.2)',
        borderColor: 'rgba(219, 165, 32, 0.2)',
        borderWidth: 1,
      }
    ]
  };
  const weekMetricsPolar = {
    labels: weekMetricsData.map(t => formatDateToUser(t.date)),
    datasets: [
      {
        label: 'CASH-IN (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_in),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'CASH-OUT (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_amount_cash_out),
        backgroundColor: 'rgba(218, 165, 32, 0.2)',
        borderColor: 'rgba(219, 165, 32, 0.2)',
        borderWidth: 1,
      }
    ]
  };

  const dailyMetricsPerUserGraphic = {
    labels: dailyMetricsPerUser.map(m => m.account_name),
    datasets: [
      {
        label: 'CASH-IN',
        data: dailyMetricsPerUser.map(m => m.transactions_summary?.total_transactions_cash_in || 0),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'CASH-OUT',
        data: dailyMetricsPerUser.map(m => m.transactions_summary?.total_transactions_cash_out || 0),
        backgroundColor: 'rgba(218, 165, 32, 0.2)',
        borderColor: 'rgba(219, 165, 32, 0.2)',
        borderWidth: 1,
      },
      {
        label: 'TEF',
        data: dailyMetricsPerUser.map(m => m.transactions_summary?.total_transactions_tef || 0),
        backgroundColor: 'rgba(250, 130, 55, 0.2)',
        borderColor: 'rgba(300, 125, 55, 0.2)',
        borderWidth: 1,
      },
    ]
  };

  const weekMetricsFee = {
    labels: weekMetricsData.map(t => formatDateToUser(t.date)),
    datasets: [
      {
        label: 'FEE (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_fee_charged),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      }
    ]
  };
  const weekMetricsFeeBar = {
    labels: weekMetricsData.map(t => formatDateToUser(t.date)),
    datasets: [
      {
        label: 'FEE (R$)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_fee_charged),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      }
    ]
  };
  const weekMetricsVolume = {
    labels: weekMetricsData.map(t => formatDateToUser(t.date)),
    datasets: [
      {
        label: 'CASH-IN (quantity)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_transactions_cash_in),
        backgroundColor: 'rgba(0, 128, 0, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'CASH-OUT (quantity)',
        data: weekMetricsData.map(m => m?.transactions_summary?.total_transactions_cash_out),
        backgroundColor: 'rgba(218, 165, 32, 0.2)',
        borderColor: 'rgba(219, 165, 32, 0.2)',
        borderWidth: 1,
      }
    ]
  };



  const groupTransactionsByUserId = (transactions) => {
    const grouped = {};
    transactions.forEach(t => {
      if (!grouped[t.account_name]) {
        grouped[t.account_name] = [];
      }
      grouped[t.account_name].push(t);
    });
    return grouped;
  };

  const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r}, ${g}, ${b}, 0.5)`;
  };

  const transactionsGroupedByUser = groupTransactionsByUserId(metrics?.transactions_per_hour || []);

  const hoursList = []
  metrics?.transactions_per_hour?.map(t => {
    const hour = t?.transaction_hour.slice(11, 16)
    if (!hoursList.includes(hour)) hoursList.push(hour)
    return t
  })

  const transactionsPerHour = {
    labels: hoursList,
    datasets: Object.entries(transactionsGroupedByUser).map(([accountName, transactions]) => ({
      label: `${accountName} ${t('Transactions')}`,
      data: transactions.map(t => t.transaction_count),
      backgroundColor: generateRandomColor(),
      borderColor: generateRandomColor(),
      borderWidth: 1,
      fill: true
    }))
  };


  const graphics = {
    weekMetrics,
    weekMetricsLine,
    weekMetricsVolume,
    transactionsPerHour,
    dailyMetricsPerUserGraphic,
    weekMetricsFee,
    weekMetricsFeeBar,
    weekMetricsDoughnut,
    weekMetricsPolar

  };

  const graphicType = {
    weekMetrics: 'Bar',
    weekMetricsLine: 'Line',
    weekMetricsVolume: 'Bar',
    transactionsPerHour: 'Line',
    weekMetricsFee: 'Line',
    dailyMetricsPerUserGraphic: 'Bar',
    weekMetricsFeeBar: 'Bar',
    weekMetricsDoughnut: 'Doughnut',
    weekMetricsPolar: 'PolarArea'

  }[graphicToDisplay]
  let graph;
  if (graphicType === 'Bar') {
    graph = <Bar data={graphics[graphicToDisplay]} options={{
      responsive: true, maintainAspectRatio: false, scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      }
    }} />
  } else if (graphicType === 'Line') {
    graph = <Line data={graphics[graphicToDisplay]} options={{
      responsive: true, maintainAspectRatio: false, scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: {
            display: false
          }
        }
      }
    }} />
  } else if (graphicType === 'Doughnut') {
    graph = <Doughnut data={graphics[graphicToDisplay]} options={{ responsive: true, maintainAspectRatio: false }} />
  } else if (graphicType === 'PolarArea') {
    graph = <PolarArea data={graphics[graphicToDisplay]} options={{ responsive: true, maintainAspectRatio: false }} />
  }
  return (
    <div className={`chart-container ${darkMode ? "dark" : ""}`}>
      {graph}
    </div>
  );
};
const Widget = ({ type, metrics }) => {
  const formatDate = (date) => {
    return format(date, 'yyyy-MM-dd');
  };
  /* if (!metrics.daily_metrics_per_user) {
  } */
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  if (isMobile) {
    import("./widgetMobile.scss");
  } else {
    import("./widget.scss");
  }
  const [balanceon, setBalanceon] = useState(false);
  useEffect(() => {
    if (metrics != undefined) {
      setBalanceon(true)
    }
  }, [metrics]);
  const { t } = useTranslation(); // Use the useTranslation hook to get the t() function
  const isTransactionsPerHour = metrics?.transactions_per_hour?.length
  const balance = metrics?.balance || 0;
  const transactionsAverageValueQuantity = metrics?.transactions_average_value_quantity || 0;
  const transactionsAverageValue = metrics?.transactions_average_value || 0;
  const transactionsPaidPerMinute = metrics?.transactions_paid_per_minute || 0;
  const feeReceived = metrics?.transactions_summary?.total_fee_charged || 0;
  const totalCashInOrders = metrics?.transactions_summary?.total_transactions_cash_in || 0;
  const totalCashInAmount = metrics?.transactions_summary?.total_amount_cash_in || 0;
  const totalCashOutOrders = metrics?.transactions_summary?.total_transactions_cash_out || 0;
  const totalCashOutAmount = metrics?.transactions_summary?.total_amount_cash_out || 0;
  const totalTefOrders = metrics?.transactions_summary?.total_transactions_tef || 0;
  const totalTefAmount = metrics?.transactions_summary?.total_amount_tef || 0;
  const customerFeeCharged = metrics?.transactions_summary?.total_fee_charged || 0;
  const transactionsSuccessRate = metrics?.transactions_success_rate || 100;
  const operationalFeeCost = ((totalCashInOrders + totalCashOutOrders) * 0.09)
  const operationalcost = ((totalCashInAmount - (totalCashOutAmount + feeReceived)))
  const totaltransactions = totalCashInOrders + totalCashOutOrders + totalTefOrders

  const d = new Date();
  let actualTimeStamp = d.toLocaleTimeString('pt-BR') + " " + d.toLocaleDateString('pt-BR')
  const formatToBRLCurrency = (rawValue) => {
    const value = parseFloat(rawValue);
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };
  const [displayText, setDisplayText] = useState('∗∗∗∗∗');

  const handleClick = () => {
    // Toggle between two different texts
    const newText = displayText === '∗∗∗∗∗' ? formatToBRLCurrency(Number(balance)) : '∗∗∗∗∗';
    setDisplayText(newText);
  };
  const [isActive, setIsActive] = useState(false);


  let data;
  const diff = 20;

  switch (type) {
    case 'transactions_per_minute':

      data = {
        title: t('Quantidade de transações'),
        tooltip: t('Total de transações efetivadas.'),
        graph: <Transactions graphicToDisplay={"weekMetricsDoughnut"} />,
        link: t('Check my metrics'),
        valueclass: "text-center text-3xl mdscreen:text-5xl ",
        value: totaltransactions ? totaltransactions : '-',
        class: "justify-center w-full h-full widget p-2.5  rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        lastupdate: actualTimeStamp,
        icon: (
          <SpeedIcon
            className="icon"
            style={{
              color: 'dodgerblue',
              backgroundColor: 'rgba(30, 144, 255, 0.2)',
            }}
          />
        ),
      };
      break;
    case 'average_ticket':
      data = {
        title: t('Ticket médio'),
        tooltip: t('Valor médio por transação.'),
        graph: <Transactions graphicToDisplay={"weekMetricsLine"} />,
        link: t('Check my fees'),
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        value: transactionsAverageValue ? formatToBRLCurrency(Number(transactionsAverageValue)) : '-',
        class: "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: transactionsAverageValueQuantity,
        topCornerIcon: null,
        icon: (
          <AttachMoneyIcon
            className="icon"
            style={{
              color: 'green',
              backgroundColor: 'rgba(0, 128, 0, 0.2)'
            }}
          />
        )
      };
      break;
    case 'fee_to_pay':
      data = {
        title: t('Taxas'),
        tooltip: t('Custos associados às transações.'),
        graph: <Transactions graphicToDisplay={"weekMetricsFeeBar"} />,
        link: t('Check my fees'),
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        value: customerFeeCharged ? formatToBRLCurrency(customerFeeCharged) : '-',
        class: "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        icon: (
          <MoneyOffIcon
            className="icon"
            style={{
              color: 'crimson',
              backgroundColor: 'rgba(255, 0, 0, 0.2)'
            }}
          />
        )
      };
      break;
    case 'fee_to_pay_admin':
      data = {
        title: t('Taxas'),
        tooltip: t('Custos associados às transações.'),
        graph: <Transactions graphicToDisplay={"weekMetricsFeeBar"} />,
        link: t('Check my fees'),
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        value: operationalFeeCost ? formatToBRLCurrency(operationalFeeCost) : '-',
        class: "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        icon: (
          <MoneyOffIcon
            className="icon"
            style={{
              color: 'crimson',
              backgroundColor: 'rgba(255, 0, 0, 0.2)'
            }}
          />
        )
      };
      break;
    case 'net_fee_received':
      data = {
        title: t('Net fee received'),
        link: t('Check my fees'),
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        graph: <Transactions graphicToDisplay={"weekMetrics"} />,
        value: feeReceived ? formatToBRLCurrency(feeReceived - operationalFeeCost) : '-',
        class: "justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: null,
        topCornerIcon: null,
        icon: (
          <AttachMoneyIcon
            className="icon"
            style={{
              color: 'green',
              backgroundColor: 'rgba(0, 128, 0, 0.2)'
            }}
          />
        )
      };
      break;
    case 'balance':
      data = {
        title: t('Balance'),
        tooltip: t('Saldo total da conta.'),
        graph: null,
        titleclass: "w-full",
        valueclass: "text-3xl mdscreen:text-5xl",
        link: t('Check my balance'),
        value: <div className='flex items-center gap-2'>
          <button onClick={handleClick}>
            <div onClick={() => setIsActive(!isActive)}>
              {isActive ? <MdVisibility color="#183350" className="cursor-pointer" size={20} /> : <MdVisibilityOff color="#183350" className="cursor-pointer" size={20} />}
            </div>
          </button>
          <div>{displayText}</div>
        </div>,
        class: "w-full shadow-xl border-t-2 border-l-2 w-3/12 widget p-2.5 rounded-lg flex flex-col",
        icon: (
          <AccountBalanceIcon
            className="icon"
            style={{
              color: 'green',
              backgroundColor: 'rgba(0, 128, 0, 0.2)'
            }}
          />
        )
      };
      break;
    case 'cash_in_orders':
      data = {
        title: t('Cash-in'),
        link: t('View all orders'),
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        tooltip: t('Transações de entrada.'),
        graph: <Transactions graphicToDisplay={"weekMetrics"} />,
        value: totalCashInAmount ? formatToBRLCurrency(totalCashInAmount) : '-',
        class: "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: totalCashInOrders ?? 0,
        icon: (
          <PixIcon
            className="icon"
            style={{
              color: 'green',
              backgroundColor: 'rgba(0, 128, 0, 0.2)'
            }}
          />
        )
      };
      break;
    case 'fees_received':
      data = {
        title: t('Fees receveid'),
        link: t('View all orders'),
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        graph: <Transactions graphicToDisplay={"weekMetrics"} />,
        value: feeReceived ? formatToBRLCurrency(feeReceived) : '-',
        class: "justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: totalTefOrders ?? 0,
        icon: (
          <PixIcon
            className="icon"
            style={{
              color: 'green',
              backgroundColor: 'rgba(0, 128, 0, 0.2)'
            }}
          />
        )
      };
      break;
    case 'cash_out_orders':
      data = {
        title: t('Cash-out'),
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        tooltip: t('Transações de saída.'),
        graph: <Transactions graphicToDisplay={"weekMetricsPolar"} />,
        link: t('View all orders'),
        value: totalCashOutAmount ? formatToBRLCurrency(totalCashOutAmount) : '-',
        class: "justify-center w-full h-full widget p-2.5 rounded-lg flex flex-col",
        diff: totalCashOutOrders ?? 0,
        icon: (
          <AccountBalanceIcon
            className="icon"
            style={{
              backgroundColor: 'rgba(218, 165, 32, 0.2)',
              color: 'goldenrod'
            }}
          />
        )
      };
      break;
    case 'operational_result':
      data = {
        title: t('Resultado operacional'),
        divtitleclass: "justify-center",
        valueclass: "text-center text-3xl mdscreen:text-5xl",
        tooltip: t('Resultado financeiro da operação.'),
        graph: null,
        link: t('View all orders'),
        value: operationalcost ? formatToBRLCurrency(operationalcost) : '-',
        class: " w-full h-full justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: "",
        icon: (
          <AccountBalanceIcon
            className="icon"
            style={{
              backgroundColor: 'rgba(218, 165, 32, 0.2)',
              color: 'goldenrod'
            }}
          />
        )
      };
      break;
    case 'fee_charged':
      data = {
        title: t('Fee paid'),
        link: t('See details'),
        graph: "",
        value: customerFeeCharged ? formatToBRLCurrency(customerFeeCharged) : '-',
        class: "justify-center widget p-2.5 rounded-lg flex flex-col",
        diff: totalCashInOrders ?? 0 + totalCashOutOrders ?? 0,
        icon: (
          <PointOfSaleIcon
            className="icon"
            style={{
              backgroundColor: 'rgba(218, 165, 32, 0.2)',
              color: 'goldenrod'
            }}
          />
        )
      };
      break;
    default:
      break;
  }

  return (
    <div className={data.class}>
      <div className={balanceon ? 'text-xl justify-center w-full flex gap-2 font-medium' : 'hidden text-xl justify-center w-full gap-2 font-medium'}>
        <span className={data.titleclass}>{data.title}</span>
        <div className='text-end flex justify-end self-center'>
          <ThemeProvider theme={theme}>
            <LightTooltip title={data.tooltip} TransitionComponent={Zoom} placement="right">
              <InfoOutlinedIcon style={{ fontSize: 'medium', verticalAlign: 'top' }} />
            </LightTooltip>
          </ThemeProvider>
        </div>
      </div>
      <div className={balanceon ? data.valueclass : 'hidden ' + data.valueclass}>
        <span className="counter font-semibold text-nowrap">
          {data.value}
        </span>
      </div>
      <div className={balanceon ? 'hidden' : 'w-full text-center'}>
        <CircularProgress color="inherit" />
      </div>
    </div>
  );
};
const Home = () => {
  const loggedUser = localStorage.getItem('username');
  const { data: metrics } = useQuery({
    queryKey: ['metrics'],
    queryFn: () => getMetrics(),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 1,
    refetchIntervalInBackground: false,
  });
  let widgets;
  if (localStorage?.getItem("is_admin")) {
    widgets = (

      <div className='h-full flex flex-col p-0 mb-3'>
        {/* <div className=' w-8/12 row-span-1 col-span-3 mb-3' >
          <Widget type="balance" metrics={metrics} />
        </div> */}
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300 w-layout-45">
            <Widget type="operational_result" metrics={metrics} />
          </div>

          <div className="p-5 border-r-2 border-zinc-300 w-layout-27">
            <Widget type="cash_in_orders" metrics={metrics} />
          </div>
          <div className="p-5 w-layout-27">
            <Widget type="cash_out_orders" metrics={metrics} />
          </div>
        </div>
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="average_ticket" metrics={metrics} />
          </div>
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="fee_to_pay_admin" metrics={metrics} />
          </div>
          <div className="p-5 w-4/12 border-zinc-300 border-t-2 border-b-2">
            <Widget type="transactions_per_minute" metrics={metrics} />
          </div>
        </div>

      </div>

    );
  } else {
    widgets = (
      <div className='h-full flex flex-col p-0 mb-3'>
        {/* <div className=' w-8/12 row-span-1 col-span-3 mb-3' >
          <Widget type="balance" metrics={metrics} />
        </div> */}
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300 w-layout-45">
            <Widget type="operational_result" metrics={metrics} />
          </div>

          <div className="p-5 border-r-2 border-zinc-300 w-layout-27">
            <Widget type="cash_in_orders" metrics={metrics} />
          </div>
          <div className="p-5 w-layout-27">
            <Widget type="cash_out_orders" metrics={metrics} />
          </div>
        </div>
        <div className="w-full h-2/3 flex">
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="average_ticket" metrics={metrics} />
          </div>
          <div className="p-5 border-r-2 border-zinc-300 w-4/12 border-t-2 border-b-2 ">
            <Widget type="fee_to_pay" metrics={metrics} />
          </div>
          <div className="p-5 w-4/12 border-zinc-300 border-t-2 border-b-2">
            <Widget type="transactions_per_minute" metrics={metrics} />
          </div>
        </div>

      </div>
    )
  } /* else if (localStorage?.getItem("is_fees_account")) {
    widgets = (
      <>
        <Widget type="balance" metrics={metrics} />
        <Widget type="fees_received" metrics={metrics} />
      </>
    );
  } else {
    widgets = (
      <div className='flex flex-col p-7'>

        <div className='grid grid-cols-3 grid-rows-5 mb-3'>
          <div className="h-full border-r-2 row-span-2 grid grid-rows-5 grid-cols-1">
            <div className=' w-11/12 mx-auto row-span-1' >
              <Widget type="balance" metrics={metrics} />
            </div>
            <div className="row-span-4">
              <Widget type="operational_result" metrics={metrics} />
            </div>
          </div>
          <div className="border-r-2">
            <Widget type="cash_out_orders" metrics={metrics} />
          </div>
          <div >
            <Widget type="fee_to_pay" metrics={metrics} />
          </div>
          <div className="border-r-2 border-t-2">
            <Widget type="average_ticket" metrics={metrics} />
          </div>
          { <Widget type="net_fee_received" metrics={metrics} /> *}
          <div className="border-t-2">
            <Widget type="transactions_per_minute" metrics={metrics} />
          </div>
        </div>

      </div>

    );
  } */

  return (
    <div className="home h-full">
      <div className="w-full h-full">{widgets}</div>
    </div>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Home />
    </QueryClientProvider>
  );
};

export default App;
