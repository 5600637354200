import axios from 'axios';
import * as Sentry from "@sentry/react";
import { format } from 'date-fns'

const ENV = 'PROD'; // DEV
const baseRoute = 'api.izipaynow.com/v2'
const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}balance`;
console.log(url)

const CACHE_TIMER = 1000 * 59;
let getStatementCache = {};
let getBalanceCache = {};
let getMetricsCache = {};
let getDocumentsCache = {};
let getUsersCache = {};

const checkIfShouldLogout = () => {
  const LOGOUT_TIMER = 1000 * 60 * 60 * 6;
  const currentTime = Date.now();
  const lastlog = localStorage.getItem("last_login");
  if (currentTime - lastlog > LOGOUT_TIMER) {
    console.log("Time to logout!");
    localStorage.clear();
    window.location.reload();
  } else {
    const timeRemaining = lastlog - currentTime;
    console.log(`Time remaining before logout: ${Math.ceil(timeRemaining / 1000)} seconds`);
  }
};


async function getStatement(page = 1, limit = 100, filters = {}) {
  try {
    const cacheKey = JSON.stringify({ page, limit, filters });
    const now = Date.now();

    if (getStatementCache[cacheKey] && (now - getStatementCache[cacheKey].time) < CACHE_TIMER) {
      return getStatementCache[cacheKey].data;
    }

    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}transactions`;
    console.log(url);
    const params = {
      page,
      limit,
      ...filters
    };
    console.log(params)
    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      },
      params,
    });

    if (response.status !== 200) {
      throw new Error('Request failed');
    }
    // Store the result in cache only if response.data.length is not null
    if (response?.data?.transactions?.length) {
      getStatementCache[cacheKey] = {
        time: now,
        data: response.data
      };
    }
    console.log(response)
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}



async function getBalance() {
  try {

    const cacheKey = 'balance';
    const now = Date.now();

    if (getBalanceCache[cacheKey] && (now - getBalanceCache[cacheKey].time) < CACHE_TIMER) {
      return getBalanceCache[cacheKey].data;
    }


    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}balance`;
    console.log(url)
    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      }
    });

    if (response.status !== 200) {
      throw new Error('Request failed');
    }

    getBalanceCache[cacheKey] = {
      time: now,
      data: response.data
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}
async function getUsers(page = 1, limit = 100, filters = {}) {
  try {
    const cacheKey = JSON.stringify({ page, limit,filters});
    const now = Date.now();
    if (getUsersCache[cacheKey] && (now - getUsersCache[cacheKey].time) < CACHE_TIMER) {
      return getUsersCache[cacheKey].data;
    }
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}users`;
    console.log(url)
    const params = {
      page,
      limit,
      ...filters
    };
    const teste = {
      page,
      limit,
      ...filters
      
    };
    console.log(teste)
    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      },
      params,
    });
    if (response.status !== 200) {
      throw new Error('Request failed');
    }
    getUsersCache[cacheKey] = {
      time: now,
      data: response.data
    };
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}
async function getAllDocuments() {
  try {
    const cacheKey = 'documents';
    const now = Date.now();
    if (getDocumentsCache[cacheKey] && (now - getDocumentsCache[cacheKey].time) < CACHE_TIMER) {
      return getDocumentsCache[cacheKey].data;
    }
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}documents`;
    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      }
    });
    if (response.status !== 200) {
      throw new Error('Request failed');
    }
    getDocumentsCache[cacheKey] = {
      time: now,
      data: response.data
    };
    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}

async function downloadCSV(date) {
  try {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const token = await authenticate(username, password);
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}transactions/csv?date=${date}`;

    const response = await axios.get(url, {
      headers: {
        Authorization: token,
        // 'Accept-Encoding': 'gzip, deflate, br'
      },
      // responseType: 'arraybuffer',  // Expecting an array buffer instead of text
    });

    console.log(`response: ${JSON.stringify(response)}`);
    if (response.status !== 200) {
      throw new Error('Request failed');
    }

    return response?.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}

async function forceUpdateMetrics() {
  try {
    const cacheKey = 'metrics';
    const now = Date.now();

    if (getMetricsCache[cacheKey] && (now - getMetricsCache[cacheKey].time) < CACHE_TIMER * 15) {
      return getMetricsCache[cacheKey].data;
    }
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}metrics`;

    const response = await axios.post(url,
      {
        date: `${format(now, 'yyyy-MM-dd')}`
      },
      {
        headers: {
          Authorization: token,
        }
      }
    );


    if (response.status !== 200 && response.status !== 204) {
      throw new Error('Request failed');
    }

    getMetricsCache[cacheKey] = {
      time: now,
      data: response.data
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}

async function getMetrics(date = null) {
  try {

    const cacheKey = 'metrics';
    const now = Date.now();

    checkIfShouldLogout();

    if (getMetricsCache[cacheKey] && (now - getMetricsCache[cacheKey].time) < CACHE_TIMER) {
      return getMetricsCache[cacheKey].data;
    }

    const today = date ? date : `${format(now, 'yyyy-MM-dd')}`
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}metrics?date=${today}`;
    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      }
    });

    if (response.status !== 200) {
      throw new Error('Request failed');
    }

    getMetricsCache[cacheKey] = {
      time: today,
      data: response.data
    };

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}

async function authenticate(username, password) {
  const storedToken = localStorage.getItem("token");
  const storedTime = localStorage.getItem("tokenTime");

  // If token and time exist in local storage and it's been less than 25 minutes since the token was stored
  if (storedToken && storedTime && new Date().getTime() - storedTime < 25 * 60 * 1000) {
    return storedToken;
  }

  try {
    const body = {
      email: username,
      password: password
    };

    const response = await axios.post(`https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}login`, body);
    console.log(response);

    if (response.status !== 200) {
      throw new Error('Request failed');
    }

    // Save token and current time in local storage
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("tokenTime", new Date().getTime());
    localStorage.setItem("last_login", Date.now());
    if (response?.data?.username) localStorage.setItem("username", response?.data?.username);
    if (response?.data?.password) localStorage.setItem("password", response?.data?.password);
    if (response?.data['2fa_email']) localStorage.setItem("2faemail", response?.data['2fa_email']);
    if (response?.data?.account_name) localStorage.setItem("account_name", response?.data?.account_name);
    if (response?.data?.virtual_account) localStorage.setItem("virtual_account", response?.data?.virtual_account);
    if (response?.data?.webhook_url) localStorage.setItem("webhook_url", response?.data?.webhook_url);
    if (response?.data?.is_admin) localStorage.setItem("is_admin", true);
    if (response?.data?.is_fees_account) localStorage.setItem("is_fees_account", true);
    return response.data.token;
  } catch (error) {
    console.error(error);
    localStorage.clear();
    window.location.reload();
    throw new Error('Something went wrong');
  }
}

async function updateUserInfo(dataToChange) {
  const storedToken = localStorage.getItem("token");
  // if(localStorage.getItem("is_admin")) return;

  try {
    const data = {
      ...dataToChange
    };
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}user`
    const response = await axios.put(url, data, {
      headers: {
        Authorization: storedToken,
        'Content-Type': 'application/json'
      }
    });

    if (response.status !== 200) {
      throw new Error('Request failed');
    }

    // Save token and current time in local storage
    if (dataToChange.dashboard_password !== localStorage.getItem("password")) {
      localStorage.clear();
    }
    if (response?.data?.token) localStorage.setItem("token", response?.data?.token);
    if (response?.data?.dashboard_login) localStorage.setItem("username", response?.data?.dashboard_login);
    if (response?.data?.password) localStorage.setItem("password", response?.data?.password);
    if (response?.data?.account_name) localStorage.setItem("account_name", response?.data?.account_name);
    if (response?.data?.virtual_account) localStorage.setItem("virtual_account", response?.data?.virtual_account);
    if (response?.data?.webhook_url) localStorage.setItem("webhook_url", response?.data?.webhook_url);
    if (response?.data?.is_admin) localStorage.setItem("is_admin", true);
    if (response?.data?.is_fees_account) localStorage.setItem("is_fees_account", true);

    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error('Something went wrong');
  }
}

async function withdraw(amount, pixKey, pinCode) {
  try {
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}withdraw`;

    const data = {
      amount: amount,
      pix_key: pixKey,
      order_id: 'manual withdraw through dasboard',
      verification_code: pinCode
    };

    const response = await axios.post(url, data, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });
    console.log('response: ', response);
    if (response.status !== 200) {
      console.log(response);
      throw new Error('Request failed');
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}

async function requestWithdraw(amount, pixKey) {
  try {
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}request-withdraw`;

    const data = {
      amount: amount,
      pix_key: pixKey,
      order_id: 'manual withdraw through dasboard'
    };

    const response = await axios.post(url, data, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });

    if (response.status !== 200) {
      throw new Error('Request failed');
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}

async function tef(amount, virtualAccount) {
  try {
    const token = localStorage.getItem("token");
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}tef`;

    const data = {
      amount: amount,
      virtual_account: virtualAccount,
      order_id: 'manual TEF through dasboard'
    };

    const response = await axios.post(url, data, {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json'
      }
    });

    if (response.status !== 200) {
      throw new Error(response?.message || 'Request failed');
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error(error?.message || 'Something went wrong');
  }
}

async function generateCashIn(amount) {
  try {
    const url = `https://${baseRoute}/${ENV === 'DEV' ? 'sandbox/' : ''}qr-code/generate`;
    console.log(url)
    const token = localStorage.getItem("token");
    console.log(token)
    const data = {
      amount: amount,
    };

    const response = await axios.post(url, data, {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    });

    console.log('response: ', response);
    if (response.status !== 200) {
      console.log(response);
      throw new Error('Request failed');
    }

    return response.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    throw new Error('Something went wrong');
  }
}




export {
  getUsers,
  getStatement,
  getAllDocuments,
  getBalance,
  authenticate,
  downloadCSV,
  getMetrics,
  withdraw,
  tef,
  forceUpdateMetrics,
  updateUserInfo,
  requestWithdraw,
  generateCashIn
};
