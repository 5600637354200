import React from "react";
import { Document, Page, View, Text, StyleSheet, pdf, Image } from "@react-pdf/renderer";
import logo from '../../../public/izipaylogo.png';

const formatCurrency = (value) => {
  const formattedValue = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);

  return formattedValue;
};

const formatDate = (value) => {
  const date = new Date(value);
  const options = { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    padding: "1cm",
  },
  header: {
    marginBottom: "1cm",
    textAlign: "center",
  },
  headerText: {
    fontSize: 18,
    fontWeight: "bold",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between", // Align children to the start and end of the row
    alignItems: "center",
    borderBottomWidth: 0,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
    padding: "0.5cm 0",
  },
  rowWithBorder: {
    flexDirection: "row",
    justifyContent: "space-between", // Align children to the start and end of the row
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    borderBottomStyle: "solid",
    padding: "0.5cm 0",
  },
  label: {
    flex: 1, // Take 1/3 of the row's width
    fontWeight: "bold",
    textAlign: "justify", // Align text to the start of the label (left)
    fontSize: 14,
  },
  value: {
    flex: 2, // Take 2/3 of the row's width
    fontWeight: "normal", // Adjust font weight as needed
    textAlign: "justify", // Align text to the end of the value (right)
    fontSize: 14,
  },
  footer: {
    position: "absolute",
    bottom: "1cm",
    left: "1cm",
    right: "1cm",
    textAlign: "center",
    color: "#777",
    fontSize: 10,
  },
  logo: {
    width: 250, // Adjust the width as needed
    height: 100, // Adjust the height as needed
    marginBottom: "1cm",
    alignSelf: "flex-start", // Align the logo to the start of the row (left)
  },
  textTop: {
    marginTop: 5,
    width: "70%", // Adjust the width as needed to fit the content
    height: 100, // Adjust the height as needed
    marginBottom: "1cm",
    alignSelf: "flex-end",
    textAlign: "right",
    fontSize: 12,
    fontWeight: "bold",
    flexWrap: "nowrap", // Prevent text from wrapping to a new line
  },
});

const getRawPDF = (transaction) => {
  const orderId = transaction.order_id 
  const endToEndId = transaction.end_to_end_id 
  const amount = transaction.amount 
  const movementType = transaction.movement_type 
  const account = transaction.account_owner_name
  const transactionDate = transaction?.last_updated_at_brazil || transaction?.last_updated_at
  const documentNumber = transaction?.document_number
  const bankName = transaction?. account_bank_name
  const bankBranch = transaction?.account_branch
  const bankAccountNumber = transaction?.account_number
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.textTop}>
            Recebimento PIX{'\n'}
            Número do Documento: {orderId}{'\n'}
            E2E ID: {endToEndId}{'\n'}
            Solicitação: {new Date().toLocaleString()}
            </Text>
        </View>
        <View style={styles.header}>
          <Text style={styles.headerText}>Detalhes da transação</Text>
        </View>

        <View key={'op_data'} style={styles.rowWithBorder}>
            <Text style={styles.label}>Dados da operação</Text>
          </View>

          <View key={movementType} style={styles.row}>
            <Text style={styles.label}>Tipo:</Text>
            <Text style={styles.value}>{movementType}</Text>
          </View>

          <View key={amount} style={styles.row}>
            <Text style={styles.label}>Valor:</Text>
            <Text style={styles.value}>{formatCurrency(amount)}</Text>
          </View>

          <View key={transactionDate} style={styles.row}>
            <Text style={styles.label}>Data da transação:</Text>
            <Text style={styles.value}>{formatDate(transactionDate)}</Text>
          </View>

          <View key={'customer_data'} style={styles.rowWithBorder}>
            <Text style={styles.label}>Dados do cliente</Text>
          </View>

          <View key={account} style={styles.row}>
            <Text style={styles.label}>Nome:</Text>
            <Text style={styles.value}>{account}</Text>
          </View>

          <View key={documentNumber} style={styles.row}>
            <Text style={styles.label}>Documento:</Text>
            <Text style={styles.value}>{documentNumber}</Text>
          </View>

          <View key={'institution_data'} style={styles.rowWithBorder}>
            <Text style={styles.label}>Dados da instituição</Text>
          </View>

          <View key={bankName} style={styles.row}>
            <Text style={styles.label}>Instituição:</Text>
            <Text style={styles.value}>{bankName?.length > 30 ? `${bankName.slice(0,30)}...` : bankName}</Text>
          </View>

          <View key={bankBranch} style={styles.row}>
            <Text style={styles.label}>Agência:</Text>
            <Text style={styles.value}>{bankBranch}</Text>
          </View>

          <View key={bankAccountNumber} style={styles.row}>
            <Text style={styles.label}>Conta:</Text>
            <Text style={styles.value}>{bankAccountNumber}</Text>
          </View>


        <View style={styles.footer}>
          <Text>Generated on: {new Date().toLocaleString()}</Text>
        </View>
      </Page>
    </Document>
  );
};

const generatePDF = async (transaction) => {
  const orderId = transaction.order_id 
  const endToEndId = transaction.end_to_end_id 
  const amount = transaction.amount 
  const movementType = transaction.movement_type 
  const account = transaction.account_owner_name
  const transactionDate = transaction?.last_updated_at_brazil || transaction?.last_updated_at
  const documentNumber = transaction?.document_number
  const bankName = transaction?. account_bank_name
  const bankBranch = transaction?.account_branch
  const bankAccountNumber = transaction?.account_number
  const pdfDoc = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image src={logo} style={styles.logo} />
          <Text style={styles.textTop}>
            Recebimento PIX{'\n'}
            Número do Documento: {orderId}{'\n'}
            E2E ID: {endToEndId}{'\n'}
            Solicitação: {new Date().toLocaleString()}
            </Text>
        </View>
        <View style={styles.header}>
          <Text style={styles.headerText}>Detalhes da transação</Text>
        </View>

        <View key={'op_data'} style={styles.rowWithBorder}>
            <Text style={styles.label}>Dados da operação</Text>
          </View>

          <View key={movementType} style={styles.row}>
            <Text style={styles.label}>Tipo:</Text>
            <Text style={styles.value}>{movementType}</Text>
          </View>

          <View key={amount} style={styles.row}>
            <Text style={styles.label}>Valor:</Text>
            <Text style={styles.value}>{formatCurrency(amount)}</Text>
          </View>

          <View key={transactionDate} style={styles.row}>
            <Text style={styles.label}>Data da transação:</Text>
            <Text style={styles.value}>{formatDate(transactionDate)}</Text>
          </View>

          <View key={'customer_data'} style={styles.rowWithBorder}>
            <Text style={styles.label}>Dados do cliente</Text>
          </View>

          <View key={account} style={styles.row}>
            <Text style={styles.label}>Nome:</Text>
            <Text style={styles.value}>{account?.length > 25 ? `${account.slice(0,25)}...` : account}</Text>
          </View>

          <View key={documentNumber} style={styles.row}>
            <Text style={styles.label}>Documento:</Text>
            <Text style={styles.value}>{documentNumber}</Text>
          </View>

          <View key={'institution_data'} style={styles.rowWithBorder}>
            <Text style={styles.label}>Dados da instituição</Text>
          </View>

          <View key={bankName} style={styles.row}>
            <Text style={styles.label}>Instituição:</Text>
            <Text style={styles.value}>{bankName?.length > 25 ? `${bankName.slice(0,25)}...` : bankName}</Text>
          </View>

          <View key={bankBranch} style={styles.row}>
            <Text style={styles.label}>Agência:</Text>
            <Text style={styles.value}>{bankBranch}</Text>
          </View>

          <View key={bankAccountNumber} style={styles.row}>
            <Text style={styles.label}>Conta:</Text>
            <Text style={styles.value}>{bankAccountNumber}</Text>
          </View>


        <View style={styles.footer}>
          <Text>Generated on: {new Date().toLocaleString()}</Text>
        </View>
      </Page>
    </Document>
  );

  const asBlob = await pdf(pdfDoc).toBlob();

  // Create a temporary URL for the PDF blob
  const pdfURL = URL.createObjectURL(asBlob);

  // Create a hidden anchor tag to trigger the download
  const downloadLink = document.createElement("a");
  downloadLink.href = pdfURL;
  downloadLink.download = `${transaction?.order_id || transaction?.transaction_id || ''}${new Date().getTime()}.pdf`;

  // Trigger the download
  downloadLink.click();

  // Clean up the temporary URL
  URL.revokeObjectURL(pdfURL);
};

export {generatePDF, getRawPDF};
