import React, { useState, useEffect, useRef } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { requestWithdraw, withdraw, tef, generateCashIn, getMetrics } from '../../services/TransactionsService';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./payments.scss";
import Widget from "../../components/widget/Widget";
import { useQuery } from "react-query";
import { InputAdornment, Alert } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from "react-i18next"; // Import useTranslation
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaArrowDown, FaArrowUp, FaCheck } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { FaRegCopy } from "react-icons/fa6";
import QRCode from 'qrcode.react';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMediaQuery } from 'react-responsive';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { FreeMode } from 'swiper/modules';
/* const queryClient = new QueryClient(); */

const Payments = () => {
    /* const { data: metrics } = useQuery({
        queryKey: ['metrics'],
        queryFn: () => getMetrics(),
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 60 * 1,
        refetchIntervalInBackground: false,
        onSuccess: (data) => {
          console.log(data)
        }
      }); */
    const [qrCodeGenerated, setQrCodeGenerated] = useState("");
    const [pixKey, setPixKey] = useState("");
    const [documentNumber, setDocumentNumber] = useState("");
    const [virtualAccount, setVirtualAccount] = useState("");
    const [amount, setAmount] = useState("");
    const [cashoutAmount, setCashoutAmount] = useState("");
    const [tefAmount, setTefAmount] = useState("");
    const [cashoutAlert, setCashoutAlert] = useState(null); //Alert for Cash Out
    const [cashinAlert, setCashinAlert] = useState(null); // Alert for Cash In
    const [tefAlert, setTefAlert] = useState(null); // Alert for TEF
    const [showModal, setShowModal] = useState(false); // State for showing modal
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [receiverData, setReceiverData] = useState(null); // State for receiver data
    const [pinCode, setPinCode] = useState(""); // State for pin code
    const { t } = useTranslation();
    const [activeButton, setActiveButton] = useState(null);
    const [open, setOpen] = useState(false);
    const [swiper, setSwiper] = useState(null);
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            boxShadow: theme.shadows[1],
            fontSize: 15,
        },
    }));
    const theme = createTheme({
        typography: {
            fontFamily: [
                'Montserrat',
                'sans-serif',
            ].join(','),
        },
        focusedTextField: {
            '&.Mui-focused': {
                borderColor: 'yellow', // Change this to the desired color
            },
        },
    });
    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                setShowModal(false)
                setShowQRCodeModal(false)
            }
        };
        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);
    const clearSuccessMessages = () => {
        setTimeout(() => {
            setCashoutAlert(null);
            setCashinAlert(null);
            setTefAlert(null);
        }, 5000);
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(qrCodeGenerated);
        setShowQRCodeModal(false);
        setQrCodeGenerated("");
        setAmount("");
    };

    const handleCashoutConfirm = async () => {
        setOpen(true);

        try {
            const amountValue = parseFloat(cashoutAmount);
            const response = await withdraw(amountValue, pixKey, pinCode);
            console.log('Transaction successful:', response);
            setCashoutAmount("")
            setPixKey("")
            setCashoutAlert({ type: 'success', text: t('Withdrawal successful!') });  // <-- Set success message
            clearSuccessMessages()
        } catch (error) {
            setOpen(false);
            console.log(error)
            console.error('An error occurred:', error);
            setCashoutAlert({ type: 'error', text: t('An error occurred during withdrawal.') });  // <-- Set error message
        } finally {
            setOpen(false)
        }
    };
    const targetDivRef = useRef(null);
    const handleClick = () => {
        // Scroll to the center of the target div
        targetDivRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };
    const handleTEFConfirm = async () => {
        setOpen(true);
        try {
            const amountValue = parseFloat(tefAmount);
            const response = await tef(amountValue, virtualAccount);
            console.log('Transaction successful:', response);
            setTefAmount("")
            setVirtualAccount("")
            setTefAlert({ type: 'success', text: t('TEF successful!') });  // <-- Set success message
            clearSuccessMessages()
        } catch (error) {
            setOpen(false);
            console.error('An error occurred:', error);
            setTefAlert({ type: 'error', text: 'An error occurred during TEF.' });  // <-- Set error message
        } finally {
            setOpen(false)
        }
    };

    const handleCashinConfirm = async () => {
        setOpen(true);
        try {
            const amountValue = parseFloat(amount);
            const response = await generateCashIn(amountValue, documentNumber);
            console.log('Transaction successful:', response);
            setQrCodeGenerated(response?.qr_code_value)
            setShowQRCodeModal(true)
            setAmount("")
            setDocumentNumber("")
            setCashinAlert({ type: 'success', text: t('Code Generated') });  // <-- Set success message
            clearSuccessMessages()
        } catch (error) {
            setOpen(false)
            console.error('An error occurred:', error);
            setCashinAlert({ type: 'error', text: 'Error generating QR Code.' });  // <-- Set error message
        } finally {
            setOpen(false)
        }
    };
    const { data: metrics, refetch } = useQuery({
        queryKey: ['metrics'],
        queryFn: () => getMetrics(),
        refetchOnWindowFocus: false,
        enabled: false
    });
    const handleCashoutClick = async () => {
        setOpen(true)
        refetch();
        try {
            const amountValue = parseFloat(cashoutAmount);
            if (parseFloat(metrics.balance) - amountValue < 0) {
                setCashoutAlert({ type: 'error', text: t('Saldo Insuficiente.') });
                setTimeout(() => {
                    setCashoutAlert(false);
                }, 2500);
                return
            }
            const response = await requestWithdraw(amountValue, pixKey);
            console.log('Transaction successful:', response);
            setReceiverData({
                name: response.sender_name,
                accountNumber: response.sender_account_number,
                bankName: response.sender_account_bank_name,
                branch: response.sender_account_branch,
                documentNumber: response.sender_document_number,
                ispb: response.sender_account_bank_ispb
            });
            setShowModal(true);
        } catch (error) {
            setOpen(false)
            console.error('An error occurred:', error);
            setCashoutAlert({ type: 'error', text: t('An error occurred during withdrawal.') }); // <-- Set error message
        } finally {
            setOpen(false)
        }
    };


    const handleModalConfirm = async () => {
        // Implement your modal confirmation logic here
        await handleCashoutConfirm()
        setShowModal(false);
    };
    const swiperRef = useRef(null)
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
        isMobile ? swiperRef.current.swiper.slideTo(buttonId) : ''
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setShowQRCodeModal(false);
    };

    const isCashInEnabled = true
    const isCashOutEnabled = true
    const isTefEnabled = true
    const [divOrder, setDivOrder] = useState([2, 1, 3]);

    const handleChangeOrder = () => {
        // Change the order of divs
        setDivOrder([2, 3, 1]); // Adjust the order as needed
    };
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    if (isMobile) {
        return (
            <ThemeProvider theme={theme}>
                <div className="receipts max-h-screen overflow-hidden">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Sidebar />
                    <div className="w-10/12">
                        <Navbar />
                        {/* <Widget /> */}
                        <div className='flex flex-col h-full'>
                            <div className="flex p-2 m-2 gap-5 w-1/2 h-1/12 self-center">
                                <Tooltip title={<Typography fontSize={15}>Cash-In</Typography>} placement="bottom" arrow>
                                    <div onClick={() => handleButtonClick(1)} className={activeButton === 1 ? 'active w-1/3 icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired' : 'w-1/3 icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired'}>
                                        <div className="flex gap-2 text-xl justify-center">
                                            <span className="hidden sm:block">{t('Depósito')}</span>
                                            <div className="self-center">
                                                <FaArrowDown className="icondeposit" />
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                                <Tooltip title={<Typography fontSize={15}>Cash-Out</Typography>} placement="bottom" arrow>
                                    <div onClick={() => handleButtonClick(2)} className={activeButton === 2 ? 'active w-1/3 icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired' : 'w-1/3 icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired'}>
                                        <div className="flex gap-2 text-xl justify-center">
                                            <span className="hidden sm:block">{t('Saque')}</span>
                                            <div className="self-center">
                                                <FaArrowUp className="iconwithdraw" />
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                                <Tooltip title={<Typography fontSize={15}>Transação Interna</Typography>} placement="bottom" arrow>
                                    <div onClick={() => handleButtonClick(3)} className={activeButton === 3 ? 'active w-1/3 icon-containertef icon-container cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired' : 'w-1/3 icon-containertef cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired'}>
                                        <div className="flex gap-1 text-xl justify-center">
                                            <span className="hidden sm:block">{t('TEF')}</span>
                                            <div className="self-center">
                                                <GrTransaction className="icontef" />
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="flex h-full">
                                <div className="h-full w-full">
                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={200}
                                        freeMode={true}
                                        modules={[FreeMode]}
                                        centeredSlides={true}
                                        direction={'vertical'}
                                        className="mySwiper h-13/14"
                                        /*  autoHeight={true} */
                                        loop={true}
                                        ref={swiperRef}
                                        initialSlide={2}
                                        navigation={false}
                                        allowTouchMove={false}
                                    >
                                        <SwiperSlide className="flex"><div className="blurredfullxl ordertransition w-11/12 flex justify-around gap-10 mx-auto  rounded border-2 h-5 border-iziblue">
                                            <div className="flex items-center gap-2 w- w-5/12">
                                                <LightTooltip title='Adicione fundos à sua conta.' TransitionComponent={Zoom} placement="left">
                                                    <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                </LightTooltip>
                                                <div>
                                                    <span className=" text-3xl font-semibold">Realizar Depósito</span>
                                                </div>
                                            </div>
                                            <div className='blurredfull blurreddiv flex gap-5 w-7/12 content-end'>
                                                <div className="flex w-2/3 justify-end">
                                                    <TextField
                                                        label="Document Number"
                                                        variant="outlined"
                                                        value='depositomanualizipay'

                                                        onChange={(e) => setDocumentNumber(e.target.value.trim())}
                                                        className="paymentComponent"
                                                        hidden
                                                        sx={{ display: "none" }}
                                                    />
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label={t("Amount (R$)")}
                                                        variant="outlined"
                                                        disabled
                                                        thousandSeparator={true}
                                                        decimalSeparator={'.'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'R$ '}
                                                        className="paymentComponent"
                                                    />
                                                </div>

                                                <div className="w-1/3 flex">
                                                    <div className="h-full">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled
                                                            className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                            style={{
                                                                backgroundColor: "green",
                                                                fontSize: "25px"
                                                            }}
                                                        >
                                                            <FaCheck className="iconcheck" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></SwiperSlide>
                                        <SwiperSlide className="flex h-full">
                                            <div className="ordertransition w-5/6 flex flex-col p-5 mx-auto rounded border-2 border-iziblue">
                                                <div className="mb-4">
                                                    {/* <div className="flex justify-end">
                                                        <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                                                            <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                        </LightTooltip>
                                                    </div> */}
                                                    <div className="flex items-center gap-2 w-full">
                                                        <div>
                                                            <span className=" text-2xl font-semibold">Realizar Depósito</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full content-end">
                                                    <div className={activeButton === 1 ? 'noblurred blurreddiv flex flex-col w-full gap-3 justify-end' : 'blurredfull gap-3 blurreddiv flex flex-col w-full justify-end'}>
                                                        <TextField
                                                            label="Info"
                                                            variant="outlined"
                                                            value='Depósito Manual Izipay'
                                                            onChange={(e) => setDocumentNumber(e.target.value.trim())}
                                                            className="paymentComponent"
                                                            disabled
                                                            sx={{ width: "100%" }}
                                                        />
                                                        <NumericFormat
                                                            customInput={TextField}
                                                            label={t("Amount (R$)")}
                                                            variant="outlined"
                                                            value={amount}
                                                            disabled={activeButton === 1 ? false : true}
                                                            onValueChange={(values) => {
                                                                const { floatValue } = values;
                                                                setAmount(floatValue ? floatValue.toFixed(2) : '');
                                                            }}
                                                            thousandSeparator={true}
                                                            decimalSeparator={'.'}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            prefix={'R$ '}
                                                            className="paymentComponent w-full rounded-l rounder-r"
                                                        />
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <div className="w-full h-full">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={activeButton === 1 ? false : true}
                                                                onClick={handleCashinConfirm}
                                                                className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                                style={{
                                                                    backgroundColor: "green",
                                                                    fontSize: "25px",
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <FaCheck className="iconcheck" />
                                                            </Button>
                                                        </div>
                                                        <div className="flex flex-col self-end">
                                                            <div>
                                                                {cashinAlert && (  // <-- New block to display status message
                                                                    <div>
                                                                        <Alert sx={{ padding: 0 }} severity={cashinAlert.type}>
                                                                            {cashinAlert.text}
                                                                        </Alert>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="flex align-text-bottom"><div className="flex self-end"><a className="flex ml-1 text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="flex h-full">
                                            <div className="ordertransition w-5/6 flex flex-col p-5 mx-auto rounded border-2 border-iziblue">
                                                <div className="mb-4">
                                                    {/* <div className="flex justify-end">
                                                        <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                                                            <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                        </LightTooltip>
                                                    </div> */}
                                                    <div className="flex items-center gap-2 w-full place-content-center">
                                                        <div>
                                                            <span className=" text-2xl font-semibold">Realizar Saque</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full content-end">
                                                    <div className={activeButton === 2 ? 'noblurred gap-3 blurreddiv flex flex-col w-full' : 'blurredfull gap-3 blurreddiv flex  flex-col w-full'}>
                                                        <TextField
                                                            label={t("Pix Key")}
                                                            variant="outlined"
                                                            value={pixKey}
                                                            disabled={activeButton === 2 ? false : true}
                                                            onChange={(e) => setPixKey(e.target.value.trim())}
                                                            className="paymentComponent"
                                                        />
                                                        <div className='w-full h-full'>
                                                            <NumericFormat
                                                                customInput={TextField}
                                                                label={t("Valor (R$)")}
                                                                variant="outlined"
                                                                value={cashoutAmount}
                                                                disabled={activeButton === 2 ? false : true}
                                                                onValueChange={(values) => {
                                                                    const { floatValue } = values;
                                                                    setCashoutAmount(floatValue ? floatValue.toFixed(2) : '');
                                                                }}
                                                                thousandSeparator={true}
                                                                decimalSeparator={'.'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                prefix={'R$ '}
                                                                className="paymentComponent w-full muinoborder"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="w-full flex flex-col">
                                                        <div className="h-full w-full">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleCashoutClick}
                                                                disabled={activeButton === 2 ? false : true}
                                                                className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                                style={{
                                                                    backgroundColor: "green",
                                                                    fontSize: "25px",
                                                                    width: "100%"
                                                                }}

                                                            >
                                                                <FaCheck className="iconcheck" />
                                                            </Button>
                                                        </div>
                                                        <div className="flex flex-col self-end">
                                                            <div>
                                                                {cashoutAlert && (  // <-- New block to display status message
                                                                    <div>
                                                                        <Alert sx={{ padding: 0 }} severity={cashoutAlert.type}>
                                                                            {cashoutAlert.text}
                                                                        </Alert>
                                                                    </div>

                                                                )}
                                                            </div>
                                                            <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="flex h-full">
                                            <div className="ordertransition w-5/6 flex flex-col p-5 mx-auto rounded border-2 border-iziblue">
                                                <div className="mb-4">
                                                    {/* <div className="flex justify-end">
                                                        <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                                                            <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                        </LightTooltip>
                                                    </div> */}
                                                    <div className="flex items-center gap-2 w-full place-content-center">
                                                        <div>
                                                            <span className=" text-2xl font-semibold">TEF</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col w-full content-end">
                                                    <div className={activeButton === 3 ? 'noblurred blurreddiv flex flex-col w-full gap-3' : 'gap-3 blurredfull blurreddiv flex  flex-col w-full'}>
                                                        <TextField
                                                            label={t("Virtual account")}
                                                            variant="outlined"
                                                            value={virtualAccount}
                                                            disabled={!isTefEnabled}
                                                            onChange={(e) => setVirtualAccount(e.target.value.trim())}
                                                            className="paymentComponent"
                                                        />
                                                        <div className='w-full h-full'>
                                                            <NumericFormat
                                                                customInput={TextField}
                                                                label={t("Amount (R$)")}
                                                                variant="outlined"
                                                                value={tefAmount}
                                                                disabled={!isTefEnabled}
                                                                onValueChange={(values) => {
                                                                    const { floatValue } = values;
                                                                    setTefAmount(floatValue ? floatValue.toFixed(2) : '');
                                                                }}
                                                                thousandSeparator={true}
                                                                decimalSeparator={'.'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                prefix={'R$ '}
                                                                className="paymentComponent w-full muinoborder"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="w-full flex flex-col">
                                                        <div className="h-full w-full">
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                disabled={activeButton === 3 ? false : true}
                                                                onClick={handleTEFConfirm}
                                                                className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                                style={{
                                                                    backgroundColor: "green",
                                                                    fontSize: "25px",
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <FaCheck className="iconcheck" />
                                                            </Button>
                                                        </div>
                                                        <div className="flex flex-col self-end">
                                                            <div>
                                                                {tefAlert && (  // <-- New block to display status message
                                                                    <div>
                                                                        <Alert sx={{ padding: 0 }} severity={tefAlert.type}>
                                                                            {tefAlert.text}
                                                                        </Alert>
                                                                    </div>

                                                                )}
                                                            </div>
                                                            <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="flex"><div className="blurredfullxl ordertransition w-11/12 flex justify-around gap-10 mx-auto rounded border-2 h-5 border-iziblue">
                                            <div className="flex items-center gap-2 w- w-5/12">
                                                <LightTooltip title='Adicione fundos à sua conta.' TransitionComponent={Zoom} placement="left">
                                                    <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                </LightTooltip>
                                                <div>
                                                    <span className=" text-3xl font-semibold">Realizar Depósito</span>
                                                </div>
                                            </div>
                                            <div className={activeButton === 1 ? 'noblurred blurreddiv flex gap-5 w-7/12 content-end' : 'blurredfull blurreddiv flex gap-5 w-7/12 content-end'}>
                                                <div className="flex w-2/3 justify-end">
                                                    <TextField
                                                        label="Document Number"
                                                        variant="outlined"
                                                        value='depositomanualizipay'

                                                        onChange={(e) => setDocumentNumber(e.target.value.trim())}
                                                        className="paymentComponent"
                                                        hidden
                                                        sx={{ display: "none" }}
                                                    />
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label={t("Amount (R$)")}
                                                        variant="outlined"
                                                        disabled
                                                        thousandSeparator={true}
                                                        decimalSeparator={'.'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'R$ '}
                                                        className="paymentComponent"
                                                    />
                                                </div>

                                                <div className="w-1/3 flex">
                                                    <div className="h-full">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled
                                                            className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                            style={{
                                                                backgroundColor: "green",
                                                                fontSize: "25px"
                                                            }}
                                                        >
                                                            <FaCheck className="iconcheck" />
                                                        </Button>
                                                    </div>
                                                    <div className="flex flex-col self-end">
                                                        <div>
                                                            {cashinAlert && (  // <-- New block to display status message
                                                                <div>
                                                                    <Alert sx={{ padding: 0 }} severity={cashinAlert.type}>
                                                                        {cashinAlert.text}
                                                                    </Alert>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="flex align-text-bottom"><div className="flex self-end"><a className="flex ml-1 text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></SwiperSlide>
                                    </Swiper>
                                </div>

                                {/* <div style={{ order: divOrder[0] }} className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                  <div className="flex items-center gap-2 w- w-5/12">
                    <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                      <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                    </LightTooltip>
                    <div>
                      <span className=" text-3xl font-semibold">Realizar Depósito</span>
                    </div>
                  </div>
                  <div className={activeButton === 1 ? 'noblurred blurreddiv flex gap-5 w-7/12 content-end' : 'blurredfull blurreddiv flex gap-5 w-7/12 content-end'}>
                    <div className="flex w-2/3 justify-end">
                      <TextField
                        label="Document Number"
                        variant="outlined"
                        value='depositomanualizipay'

                        onChange={(e) => setDocumentNumber(e.target.value.trim())}
                        className="paymentComponent"
                        hidden
                        sx={{ display: "none" }}
                      />
                      <NumericFormat
                        customInput={TextField}
                        label={t("Amount (R$)")}
                        variant="outlined"
                        value={amount}
                        disabled={activeButton === 1 ? false : true}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          setAmount(floatValue ? floatValue.toFixed(2) : '');
                        }}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'R$ '}
                        className="paymentComponent"
                      />
                    </div>

                    <div className="w-1/3 flex">
                      <div className="h-full">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={activeButton === 1 ? false : true}
                          onClick={handleCashinConfirm}
                          className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                          style={{
                            backgroundColor: "green",
                            fontSize: "25px"
                          }}
                        >
                          <FaCheck className="iconcheck" />
                        </Button>
                      </div>
                      <div className="flex flex-col self-end">
                        <div>
                          {cashinAlert && (  // <-- New block to display status message
                            <div>
                              <Alert sx={{ padding: 0 }} severity={cashinAlert.type}>
                                {cashinAlert.text}
                              </Alert>
                            </div>
                          )}
                        </div>
                        <div className="flex align-text-bottom"><div className="flex self-end"><a className="flex ml-1 text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ order: divOrder[1] }} className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                  <div className="flex items-center gap-2 w-5/12">
                    <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                      <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                    </LightTooltip>
                    <div>
                      <span className=" text-3xl font-semibold">Realizar Saque</span>
                    </div>
                  </div>
                  <div className={activeButton === 2 ? 'noblurred blurreddiv flex gap-5 w-7/12 content-end' : 'blurredfull blurreddiv flex gap-5 w-7/12 content-end'}>
                    <div className="flex gap-3 w-2/3">
                      <TextField
                        label={t("Pix Key")}
                        variant="outlined"
                        value={pixKey}
                        disabled={activeButton === 2 ? false : true}
                        onChange={(e) => setPixKey(e.target.value.trim())}
                        className="paymentComponent"
                      />
                      <NumericFormat
                        customInput={TextField}
                        label={t("Amount (R$)")}
                        variant="outlined"
                        value={cashoutAmount}
                        disabled={activeButton === 2 ? false : true}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          setCashoutAmount(floatValue ? floatValue.toFixed(2) : '');
                        }}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'R$ '}
                        className="paymentComponent"
                      />
                    </div>

                    <div className="w-1/3 flex">
                      <div className="h-full">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCashoutClick}
                          disabled={activeButton === 2 ? false : true}
                          className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                          style={{
                            backgroundColor: "green",
                            fontSize: "25px"
                          }}

                        >
                          <FaCheck className="iconcheck" />
                        </Button>
                      </div>
                      <div className="flex flex-col self-end">
                        <div>
                          {cashoutAlert && (  // <-- New block to display status message
                            <div>
                              <Alert sx={{ padding: 0 }} severity={cashoutAlert.type}>
                                {cashoutAlert.text}
                              </Alert>
                            </div>

                          )}
                        </div>
                        <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                      </div>
                    </div>

                  </div>

                </div>
                <div style={{ order: divOrder[2] }} ref={targetDivRef} className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                  <div className="flex items-center gap-2 w-5/12">
                    <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                      <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                    </LightTooltip>
                    <div>
                      <span className=" text-3xl font-semibold">TEF</span>
                    </div>
                  </div>
                  <div className={activeButton === 3 ? 'noblurred blurreddiv flex gap-5 w-7/12 content-end' : 'blurredfull blurreddiv flex gap-5 w-7/12 content-end'}>
                    <div className="flex gap-3 w-2/3">
                      <TextField
                        label={t("Virtual account")}
                        variant="outlined"
                        value={virtualAccount}
                        disabled={!isTefEnabled}
                        onChange={(e) => setVirtualAccount(e.target.value.trim())}
                        className="paymentComponent"
                      />
                      <NumericFormat
                        customInput={TextField}
                        label={t("Amount (R$)")}
                        variant="outlined"
                        value={tefAmount}
                        disabled={!isTefEnabled}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          setTefAmount(floatValue ? floatValue.toFixed(2) : '');
                        }}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'R$ '}
                        className="paymentComponent"
                      />
                    </div>
                    <div className="w-1/3 flex">
                      <div className="h-full">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={activeButton === 3 ? false : true}
                          onClick={handleTEFConfirm}
                          className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                          style={{
                            backgroundColor: "green",
                            fontSize: "25px"
                          }}
                        >
                          <FaCheck className="iconcheck" />
                        </Button>
                      </div>
                      <div className="flex flex-col self-end">
                        <div>
                          {tefAlert && (  // <-- New block to display status message
                            <div>
                              <Alert sx={{ padding: 0 }} severity={tefAlert.type}>
                                {tefAlert.text}
                              </Alert>
                            </div>

                          )}
                        </div>
                        <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                      </div>
                    </div>
                  </div>
                </div> */}
                            </div>
                        </div>
                    </div>
                    {showModal && (
                        <div className=" fixed z-10 left-0 top-0 w-full h-full bg-zinc-200 flex items-center justify-center">
                            <div className=" bg-white p-5 rounded-md shadow max-w-lg w-4/5">
                                <div className="flex text-center justify-end">
                                    <div>
                                        <IconButton sx={{ padding: 0 }} onClick={handleCloseModal} aria-label="delete">
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <Typography variant="h5" component="h2" className="title">
                                        {t('Paying to')}
                                        <LightTooltip title='Verifique o código no seu email cadastrado.' TransitionComponent={Zoom} placement="bottom" arrow>
                                            <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                        </LightTooltip>
                                    </Typography>
                                </div>


                                <Typography variant="body1" component="p" className="modalText">
                                    Nome: {receiverData.name}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Número da Conta: {receiverData.accountNumber}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Código  do Banco: {receiverData.bankName}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Agência: {receiverData.branch}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Número do Documento: {receiverData.documentNumber}
                                </Typography>
                                <TextField
                                    inputProps={{
                                        style: {
                                            padding: "16px 12px",
                                            borderColor: '#283650'
                                        }
                                    }}
                                    sx={{ borderColor: 'green' }}
                                    label="Código Pin"
                                    variant="outlined"
                                    value={pinCode}
                                    onChange={(e) => setPinCode(e.target.value.trim())}
                                    className="w-full"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment sx={{ borderColor: 'green' }} position="end">

                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: '#283650'
                                                    }}
                                                    onClick={handleModalConfirm}
                                                    className="modalButton"
                                                >
                                                    {t('Confirm')}
                                                </Button>
                                            </InputAdornment>
                                        ),

                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {showQRCodeModal && (
                        <div className="fixed z-10 left-0 top-0 w-full h-full bg-zinc-200 flex items-center justify-center">
                            <div className="bg-white p-5 rounded-md shadow max-w-lg w-4/5">
                                <div className="flex m-2 justify-center">
                                    <QRCode value={qrCodeGenerated} />
                                </div>
                                <div className="text-center"><span className="title">{t('Deposit into my account')}</span></div>
                                <div className=" my-2 break-words">
                                    {qrCodeGenerated}
                                </div>
                                <div className="flex justify-end"><button onClick={handleCopyToClipboard} className="flex gap-2 items-center rounded-full px-3 py-1 border-iziblue text-zinc-400 hover:shadow-lg"><FaRegCopy /><span>{t('Copy PIX code')}</span></button></div>
                            </div>
                        </div>
                    )}
                </div>
            </ThemeProvider>
        );
    } else {
        return (
            <ThemeProvider theme={theme}>


                <div className="receipts">
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Sidebar />
                    <div className="w-full">
                        <Navbar />
                        {/* <Widget /> */}
                        <div className='flex flex-col h-[calc(100%-112px)]'>
                            <div className="w-11/12 mx-auto">
                                <div className="flex mt-5 mb-5 gap-5 w-1/2 self-start">
                                    <Tooltip title={<Typography fontSize={15}>Cash-In</Typography>} placement="bottom" arrow>
                                        <div onClick={() => handleButtonClick(1)} className={activeButton === 1 ? 'active w-1/3 icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired' : 'w-1/3 icon-containerdeposit cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired'}>
                                            <div className="flex gap-2 text-xl justify-center">
                                                <span className="hidden sm:block">{t('Depósito')}</span>
                                                <div className="self-center">
                                                    <FaArrowDown className="icondeposit" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={<Typography fontSize={15}>Cash-Out</Typography>} placement="bottom" arrow>
                                        <div onClick={() => handleButtonClick(2)} className={activeButton === 2 ? 'active w-1/3 icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired' : 'w-1/3 icon-containerwithdraw cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired'}>
                                            <div className="flex gap-2 text-xl justify-center">
                                                <span className="hidden sm:block">{t('Saque')}</span>
                                                <div className="self-center">
                                                    <FaArrowUp className="iconwithdraw" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={<Typography fontSize={15}>Transação Interna</Typography>} placement="bottom" arrow>
                                        <div onClick={() => handleButtonClick(3)} className={activeButton === 3 ? 'active w-1/3 icon-containertef icon-container cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired' : 'w-1/3 icon-containertef cursor-pointer bg-iziblue text-white font-bold py-2 px-2 sm:px-4 rounded hover:bg-izired active:bg-izired'}>
                                            <div className="flex gap-1 text-xl justify-center">
                                                <span className="hidden sm:block">{t('TEF')}</span>
                                                <div className="self-center">
                                                    <GrTransaction className="icontef" />
                                                </div>
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="flex h-full">
                                <div className="h-full w-full flex">
                                    <div className="flex h-4/6 w-full flex-col justify-between">
                                        <div className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                                            <div className="flex items-center gap-2 w- w-5/12">
                                                <LightTooltip title='Adicione fundos à sua conta.' TransitionComponent={Zoom} placement="left">
                                                    <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                </LightTooltip>
                                                <div>
                                                    <span className=" text-3xl font-semibold">{t('Realizar Depósito')}</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-5 w-7/12 content-end">
                                                <div className={activeButton === 1 ? 'noblurred blurreddiv flex w-2/3 justify-end' : 'blurredfull blurreddiv flex w-2/3 justify-end'}>
                                                    <TextField
                                                        label="Document Number"
                                                        variant="outlined"
                                                        value='depositomanualizipay'

                                                        onChange={(e) => setDocumentNumber(e.target.value.trim())}
                                                        className="paymentComponent"
                                                        hidden
                                                        sx={{ display: "none" }}
                                                    />
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label={t("Amount (R$)")}
                                                        variant="outlined"
                                                        value={amount}
                                                        disabled={activeButton === 1 ? false : true}
                                                        onValueChange={(values) => {
                                                            const { floatValue } = values;
                                                            setAmount(floatValue ? floatValue.toFixed(2) : '');
                                                        }}
                                                        thousandSeparator={true}
                                                        decimalSeparator={'.'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'R$ '}
                                                        className="paymentComponent"
                                                    />
                                                </div>

                                                <div className="w-1/3 flex">
                                                    <div className="h-full">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={activeButton === 1 ? false : true}
                                                            onClick={handleCashinConfirm}
                                                            className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                            style={{
                                                                backgroundColor: "green",
                                                                fontSize: "25px"
                                                            }}
                                                        >
                                                            <FaCheck className="iconcheck" />
                                                        </Button>
                                                    </div>
                                                    <div className="flex flex-col self-end">
                                                        <div className={cashinAlert ? "alertanimate absolute alertbox right-0 p-2 z-50" : " absolute alertbox right-0 p-2 z-50"}>
                                                            {cashinAlert && (  // <-- New block to display status message
                                                                <div>
                                                                    <Alert severity={cashinAlert.type}>
                                                                        {cashinAlert.text}
                                                                    </Alert>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="flex align-text-bottom"><div className="flex self-end"><a className="flex ml-1 text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                                            <div className="flex items-center gap-2 w-5/12">
                                                <LightTooltip title='Retire fundos da sua conta.' TransitionComponent={Zoom} placement="left">
                                                    <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                </LightTooltip>
                                                <div>
                                                    <span className=" text-3xl font-semibold">{t('Realizar Saque')}</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-5 w-7/12 content-end">
                                                <div className={activeButton === 2 ? 'noblurred blurreddiv flex  gap-3 w-2/3' : 'blurredfull blurreddiv flex  gap-3 w-2/3'}>
                                                    <TextField
                                                        label={t("Pix Key")}
                                                        variant="outlined"
                                                        value={pixKey}
                                                        disabled={activeButton === 2 ? false : true}
                                                        onChange={(e) => setPixKey(e.target.value.trim())}
                                                        className="paymentComponent"
                                                    />
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label={t("Amount (R$)")}
                                                        variant="outlined"
                                                        value={cashoutAmount}
                                                        disabled={activeButton === 2 ? false : true}
                                                        onValueChange={(values) => {
                                                            const { floatValue } = values;
                                                            setCashoutAmount(floatValue ? floatValue.toFixed(2) : '');
                                                        }}
                                                        thousandSeparator={true}
                                                        decimalSeparator={'.'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'R$ '}
                                                        className="paymentComponent"
                                                    />
                                                </div>

                                                <div className="w-1/3 flex">
                                                    <div className="h-full">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={handleCashoutClick}
                                                            disabled={activeButton === 2 ? false : true}
                                                            className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                            style={{
                                                                backgroundColor: "green",
                                                                fontSize: "25px"
                                                            }}

                                                        >
                                                            <FaCheck className="iconcheck" />
                                                        </Button>
                                                    </div>
                                                    <div className="flex flex-col self-end">
                                                        <div className={cashoutAlert ? "alertanimate absolute alertbox right-0 p-2 z-50" : " absolute alertbox right-0 p-2 z-50"}>
                                                            {cashoutAlert && (
                                                                <div>
                                                                    <Alert severity={cashoutAlert.type}>
                                                                        {cashoutAlert.text}
                                                                    </Alert>
                                                                </div>

                                                            )}
                                                        </div>
                                                        <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                                            <div className="flex items-center gap-2 w-5/12">
                                                <LightTooltip title='Transfira fundos entre contas internas.' TransitionComponent={Zoom} placement="left">
                                                    <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                                </LightTooltip>
                                                <div>
                                                    <span className=" text-3xl font-semibold">TEF</span>
                                                </div>
                                            </div>
                                            <div className="flex gap-5 w-7/12 content-end">
                                                <div className={activeButton === 3 ? 'noblurred blurreddiv flex  gap-3 w-2/3' : 'blurredfull blurreddiv flex  gap-3 w-2/3'}>
                                                    <TextField
                                                        label={t("Virtual account")}
                                                        variant="outlined"
                                                        value={virtualAccount}
                                                        disabled={!isTefEnabled}
                                                        onChange={(e) => setVirtualAccount(e.target.value.trim())}
                                                        className="paymentComponent"
                                                    />
                                                    <NumericFormat
                                                        customInput={TextField}
                                                        label={t("Amount (R$)")}
                                                        variant="outlined"
                                                        value={tefAmount}
                                                        disabled={!isTefEnabled}
                                                        onValueChange={(values) => {
                                                            const { floatValue } = values;
                                                            setTefAmount(floatValue ? floatValue.toFixed(2) : '');
                                                        }}
                                                        thousandSeparator={true}
                                                        decimalSeparator={'.'}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'R$ '}
                                                        className="paymentComponent"
                                                    />
                                                </div>
                                                <div className="w-1/3 flex">
                                                    <div className="h-full">
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={activeButton === 3 ? false : true}
                                                            onClick={handleTEFConfirm}
                                                            className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                                                            style={{
                                                                backgroundColor: "green",
                                                                fontSize: "25px"
                                                            }}
                                                        >
                                                            <FaCheck className="iconcheck" />
                                                        </Button>
                                                    </div>
                                                    <div className="flex flex-col self-end">
                                                        <div className={tefAlert ? "alertanimate absolute alertbox right-0 p-2 z-50" : " absolute alertbox right-0 p-2 z-50"}>
                                                            {tefAlert && (  // <-- New block to display status message
                                                                <div>
                                                                    <Alert severity={tefAlert.type}>
                                                                        {tefAlert.text}
                                                                    </Alert>
                                                                </div>

                                                            )}
                                                        </div>
                                                        <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {





                                    /* <div style={{ order: divOrder[0] }} className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                  <div className="flex items-center gap-2 w- w-5/12">
                    <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                      <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                    </LightTooltip>
                    <div>
                      <span className=" text-3xl font-semibold">Realizar Depósito</span>
                    </div>
                  </div>
                  <div className={activeButton === 1 ? 'noblurred blurreddiv flex gap-5 w-7/12 content-end' : 'blurredfull blurreddiv flex gap-5 w-7/12 content-end'}>
                    <div className="flex w-2/3 justify-end">
                      <TextField
                        label="Document Number"
                        variant="outlined"
                        value='depositomanualizipay'

                        onChange={(e) => setDocumentNumber(e.target.value.trim())}
                        className="paymentComponent"
                        hidden
                        sx={{ display: "none" }}
                      />
                      <NumericFormat
                        customInput={TextField}
                        label={t("Amount (R$)")}
                        variant="outlined"
                        value={amount}
                        disabled={activeButton === 1 ? false : true}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          setAmount(floatValue ? floatValue.toFixed(2) : '');
                        }}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'R$ '}
                        className="paymentComponent"
                      />
                    </div>

                    <div className="w-1/3 flex">
                      <div className="h-full">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={activeButton === 1 ? false : true}
                          onClick={handleCashinConfirm}
                          className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                          style={{
                            backgroundColor: "green",
                            fontSize: "25px"
                          }}
                        >
                          <FaCheck className="iconcheck" />
                        </Button>
                      </div>
                      <div className="flex flex-col self-end">
                        <div>
                          {cashinAlert && (  // <-- New block to display status message
                            <div>
                              <Alert sx={{ padding: 0 }} severity={cashinAlert.type}>
                                {cashinAlert.text}
                              </Alert>
                            </div>
                          )}
                        </div>
                        <div className="flex align-text-bottom"><div className="flex self-end"><a className="flex ml-1 text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ order: divOrder[1] }} className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                  <div className="flex items-center gap-2 w-5/12">
                    <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                      <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                    </LightTooltip>
                    <div>
                      <span className=" text-3xl font-semibold">Realizar Saque</span>
                    </div>
                  </div>
                  <div className={activeButton === 2 ? 'noblurred blurreddiv flex gap-5 w-7/12 content-end' : 'blurredfull blurreddiv flex gap-5 w-7/12 content-end'}>
                    <div className="flex gap-3 w-2/3">
                      <TextField
                        label={t("Pix Key")}
                        variant="outlined"
                        value={pixKey}
                        disabled={activeButton === 2 ? false : true}
                        onChange={(e) => setPixKey(e.target.value.trim())}
                        className="paymentComponent"
                      />
                      <NumericFormat
                        customInput={TextField}
                        label={t("Amount (R$)")}
                        variant="outlined"
                        value={cashoutAmount}
                        disabled={activeButton === 2 ? false : true}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          setCashoutAmount(floatValue ? floatValue.toFixed(2) : '');
                        }}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'R$ '}
                        className="paymentComponent"
                      />
                    </div>

                    <div className="w-1/3 flex">
                      <div className="h-full">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleCashoutClick}
                          disabled={activeButton === 2 ? false : true}
                          className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                          style={{
                            backgroundColor: "green",
                            fontSize: "25px"
                          }}

                        >
                          <FaCheck className="iconcheck" />
                        </Button>
                      </div>
                      <div className="flex flex-col self-end">
                        <div>
                          {cashoutAlert && (  // <-- New block to display status message
                            <div>
                              <Alert sx={{ padding: 0 }} severity={cashoutAlert.type}>
                                {cashoutAlert.text}
                              </Alert>
                            </div>

                          )}
                        </div>
                        <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                      </div>
                    </div>

                  </div>

                </div>
                <div style={{ order: divOrder[2] }} ref={targetDivRef} className="ordertransition w-11/12 flex justify-around gap-10 p-5 mx-auto m-5 rounded border-2 border-iziblue">
                  <div className="flex items-center gap-2 w-5/12">
                    <LightTooltip title='Para download do CSV selecionar no máximo 7 dias.' TransitionComponent={Zoom} placement="left">
                      <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                    </LightTooltip>
                    <div>
                      <span className=" text-3xl font-semibold">TEF</span>
                    </div>
                  </div>
                  <div className={activeButton === 3 ? 'noblurred blurreddiv flex gap-5 w-7/12 content-end' : 'blurredfull blurreddiv flex gap-5 w-7/12 content-end'}>
                    <div className="flex gap-3 w-2/3">
                      <TextField
                        label={t("Virtual account")}
                        variant="outlined"
                        value={virtualAccount}
                        disabled={!isTefEnabled}
                        onChange={(e) => setVirtualAccount(e.target.value.trim())}
                        className="paymentComponent"
                      />
                      <NumericFormat
                        customInput={TextField}
                        label={t("Amount (R$)")}
                        variant="outlined"
                        value={tefAmount}
                        disabled={!isTefEnabled}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          setTefAmount(floatValue ? floatValue.toFixed(2) : '');
                        }}
                        thousandSeparator={true}
                        decimalSeparator={'.'}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        prefix={'R$ '}
                        className="paymentComponent"
                      />
                    </div>
                    <div className="w-1/3 flex">
                      <div className="h-full">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={activeButton === 3 ? false : true}
                          onClick={handleTEFConfirm}
                          className="paymentComponent paymentButton h-full bg-green-700 icon-containercheck"
                          style={{
                            backgroundColor: "green",
                            fontSize: "25px"
                          }}
                        >
                          <FaCheck className="iconcheck" />
                        </Button>
                      </div>
                      <div className="flex flex-col self-end">
                        <div>
                          {tefAlert && (  // <-- New block to display status message
                            <div>
                              <Alert sx={{ padding: 0 }} severity={tefAlert.type}>
                                {tefAlert.text}
                              </Alert>
                            </div>

                          )}
                        </div>
                        <div className="flex align-text-bottom"><div className="flex self-end"><a className=" ml-1 flex  text-zinc-400 text-xsm text-nowrap" href="https://www.bcb.gov.br/en/currencyconversion" target="_blank">{t("*Conversor de Moeda")}</a></div></div>
                      </div>
                    </div>
                  </div>
                </div> */}
                            </div>
                        </div>
                    </div>
                    {showModal && (
                        <div className="modalContainer">
                            <div className="modalContent">
                                <div className="flex text-center justify-end">
                                    <div>
                                        <IconButton sx={{ padding: 0 }} onClick={handleCloseModal} aria-label="delete">
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <Typography variant="h5" component="h2" className="title">
                                        {t('Beneficiary')}
                                        <LightTooltip title='Verifique o PIN no seu email cadastrado.' TransitionComponent={Zoom} placement="bottom" arrow>
                                            <InfoOutlinedIcon style={{ fontSize: 'large', verticalAlign: 'top' }} />
                                        </LightTooltip>
                                    </Typography>
                                </div>


                                <Typography variant="body1" component="p" className="modalText">
                                    Nome: {receiverData.name}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Número da Conta: {receiverData.accountNumber}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Código  do Banco: {receiverData.bankName}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Agência: {receiverData.branch}
                                </Typography>
                                <Typography variant="body1" component="p" className="modalText">
                                    Número do Documento: {receiverData.documentNumber}
                                </Typography>
                                <TextField
                                    inputProps={{
                                        style: {
                                            padding: "16px 12px",
                                            borderColor: '#283650'
                                        }
                                    }}
                                    sx={{ borderColor: 'green' }}
                                    label="Pin"
                                    variant="outlined"
                                    value={pinCode}
                                    onChange={(e) => setPinCode(e.target.value.trim())}
                                    className="w-full"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment sx={{ borderColor: 'green' }} position="end">

                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: '#283650'
                                                    }}
                                                    onClick={handleModalConfirm}
                                                    className="modalButton"
                                                >
                                                    {t('Confirm')}
                                                </Button>
                                            </InputAdornment>
                                        ),

                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {showQRCodeModal && (
                        <div className="modalContainer">
                            <div className="modalContent">
                                <div className="flex text-center justify-end">
                                    <div>
                                        <IconButton sx={{ padding: 0 }} onClick={handleCloseModal} aria-label="delete">
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="flex m-2 justify-center">
                                    <QRCode value={qrCodeGenerated} />
                                </div>
                                <div className="text-center"><span className="title">{t('Deposit into my account')}</span></div>
                                <div className=" my-2 break-words">
                                    {qrCodeGenerated}
                                </div>
                                <div className="flex justify-end"><button onClick={handleCopyToClipboard} className="flex gap-2 items-center rounded-full px-3 py-1 border-iziblue text-zinc-400 hover:shadow-lg"><FaRegCopy /><span>{t('Copy PIX code')}</span></button></div>
                            </div>
                        </div>
                    )}
                </div>

            </ThemeProvider>
        );
    }

};

export default Payments;
