import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import Home from "./pages/home/Home";
import UserList from "./pages/admin/UserList";
import Transactions from "./pages/list/List";
import Receipts from "./pages/receipts/Receipts";
import Payments from "./pages/payments/Payments";
import Profile from "./pages/profile/Profile";
import Admin from "./pages/admin/Admin";
import Login from "./components/login/Login";
import Loginexa from "./components/login/Loginexa";
import Loginexb from "./components/login/Loginexb";
import "./style/dark.scss";
import "./App.css";
import { DarkModeContext } from "./context/darkModeContext";
import { I18nextProvider } from "react-i18next"; // Import I18nextProvider
import i18n from "../i18n"; // Import your i18n configuration
const queryClient = new QueryClient();
function App() {
  const { darkMode } = useContext(DarkModeContext);
  const loggedUser = localStorage.getItem("username");
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          {loggedUser ? (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/receipts" element={<Receipts />} />
              <Route path="/payments" element={<QueryClientProvider client={queryClient}><Payments /></QueryClientProvider>} />
              <Route path="/profile" element={<Profile />} />
              {/* <Route path="/admin" element={<Admin/>} /> */}
              {/* <Route path="/userlist" element={<UserList/>} /> */}
              <Route path="*" element={<Home />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Loginexb />} />
              <Route path="*" element={<Loginexb />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function AppWithI18n() {
  return (
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  );
}

export default AppWithI18n;
