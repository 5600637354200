import React, { lazy, Suspense } from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { QueryClient, QueryClientProvider } from "react-query";
import { useMediaQuery } from 'react-responsive';
import "./home.scss";
import { useTranslation } from 'react-i18next'; // Import useTranslation

const queryClient = new QueryClient();

const Home = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isAdmin = localStorage.getItem("is_admin");

  if (isMobile) {
    import("./mobileHome.scss");
  } else {
    import("./home.scss");
  }

  // Dynamically import the table based on the device type
  const Table = lazy(() => isMobile
    ? import("../../components/table/Table")
    : import("../../components/table/Table")
  );

  const Widget = lazy(() => isMobile
    ? import("../../components/widget/WidgetMobile")
    : import("../../components/widget/Widget")
  );

  const { t } = useTranslation();

  return (
    <div className="home">
      <Sidebar />
      <div className=" w-10/12 sm:w-full homeContainer">
        <QueryClientProvider client={queryClient}>
          <Navbar />
        </QueryClientProvider>
        <div className='h-[calc(100%-112px)]'>
          <div className='h-full'>
            <div className=' h-2/3'>
              <Suspense fallback={<div>Loading...</div>}>
                <Widget />
              </Suspense>
            </div>
            <div className="p-0 m-0 sm:p-0 sm:m-0 h-1/3">
              <div className=" font-semibold mb-1 sm:mb-4 flex justify-center items-center text-center text-xl">{t('Transactions per hour')}</div>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Table graphicToDisplay={'transactionsPerHour'} />
                </Suspense>
              </QueryClientProvider>
            </div>
          </div>
          {/*<div className="halfListContainersWrapper">
            <div className="halfListContainer">
              <div className="listTitle">{t('Weekly movement')}</div>
               <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Table graphicToDisplay={'weekMetrics'} />
                </Suspense>
              </QueryClientProvider>
            </div>
            <div className="halfListContainer">
              <div className="listTitle">{isAdmin ? t('Daily movement') : t('Weekly movement')}</div>
              <QueryClientProvider client={queryClient}>
                <Suspense fallback={<div>Loading...</div>}>
                  <Table graphicToDisplay={isAdmin ? 'dailyMetricsPerUserGraphic' : 'weekMetricsVolume'} />
                </Suspense>
              </QueryClientProvider>
            </div>
          </div>
          <div className="listContainer">
            <div className="listTitle">{t('Weekly Fees')}</div>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<div>Loading...</div>}>
                <Table graphicToDisplay={'weekMetricsFee'} />
              </Suspense>
            </QueryClientProvider>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;